<template>
  <div
    @click.self="close_window"
    class="fixed w-full h-full inset-0 flex z-30">
    <div
      class="m-auto overflow-auto max-h-screen shadow-xl xlogin-window p-5 border-2 border-gray-200 max-w-2xl bg-white"
    >
      <h1 class="font-semibold text-xl flex justify-between">
        <span>Impressum</span>
        <span
          @click="close_window"
          class="cursor-pointer right-0 -mt-2 text-gray-800"
        >
          <font-awesome-icon icon="times" />
        </span>
      </h1>

      <h2 class="mt-3 text-lg font-semibold">Verantwortlich für den Inhalt</h2>
      <p class="mt-2 mb-4">
        Amt für Volkswirtschaft<br>
        Poststrasse 1<br>
        9494 Schaan<br>
        Liechtenstein<br>
        <br>
        <a href="https://www.avw.llv.li/" target="_blank">www.avw.llv.li</a>
      </p>

      <p class="mt-1 text-sm">
        © 2023 Amt für Volkswirtschaft.
        Die Inhalte mit den dazu verwendeten Bildern stehen unter dem Copyright des Amtes für Volkswirtschaft (AVW). Jegliche Verwendung muss beim Amt für Volkswirtschaft (AVW) angefragt und genehmigt werden.
      </p>

      <h2 class="mt-4 text-lg font-semibold">Konzept und Programmierung</h2>
      <p class="mt-1">
        <a href="https://wicom.li/" target="_blank">wicom.li</a>
      </p>
    </div>
  </div>
</template>

<script>
import EventBus from '../utils/event-bus.js'

export default {
  methods: {
    close_window() {
      EventBus.$emit('close-impressum-window')
    }
  }
}
</script>
