<template>
  <div class="mt-4 sm:mt-24">
    <div class="relative login-window bg-white w-1/1 sm:w-2/3 px-8 pt-5 pb-8">

      <small class="text-sm absolute right-0 top-0 mr-3 mt-2">EKZ</small>

      <h1 class="text-2xl font-normal">Herzlich willkommen</h1>

      <p class="mt-4 text-sm">
        Um Ihren Antrag <strong class="font-bold">EKZ</strong> einzureichen,
        bitten wir Sie, sich mit Ihrer Email-Adresse anzumelden. Sie erhalten an
        die eingegebene Adresse einen Zugangs-Token (Einmal-Passwort)
        übermittelt. Die Token können beliebig oft angefordert werden.
      </p>

      <p class="mt-2 text-sm mb-6">
        Alle Ihre Antragsdaten werden unter der eingegebenen Email-Adresse
        gespeichert. Der Status aller Vorgänge ist jederzeit sichtbar.
      </p>

      <form @submit="submit_form">

        <div
          v-if="!old_browser && !login_state_email && !login_state_token"
          class="mt-4 flex gap-1"
        >
          <input
            v-model="email"
            ref="email"
            autocomplete="email"
            class="border border-1 border-gray-400 px-2 py-2 shadow-sm"
            type="email"
            required
            placeholder="Email Adresse">
          <button
            type="submit"
            class="ml-1 bg-blue-500 text-white px-2 py-2 shadow-sm">
            Zugang anfordern
          </button>
        </div>

        <div
          v-if="login_state_email && !login_state_token"
          class="mt-4 flex gap-1">
          <input
            v-model="token"
            ref="token"
            class="w-24 border border-1 border-gray-400 px-3 py-2 uppercase shadow-sm"
            type="text"
            lengh="4"
            required
            placeholder="TOKEN">
          <button
            type="submit"
            class="ml-1 bg-green-500 text-white px-3 py-2 shadow-sm">
            TOKEN bestätigen
          </button>
        </div>

      </form>

      <div class="mt-6 text-sm font-medium">
        Bitte beachten Sie, dass diese Antragserfassung einen modernen Browser
        (<a
          href="https://www.mozilla.org/de/firefox/all/#product-desktop-release"
          class="underline hover:text-green-600"
          target="_blank">Firefox</a>,
        <a
          href="https://www.google.com/intl/de/chrome/"
          target="_blank"
          class="underline">Chrome</a>,
        <a
          href="https://support.apple.com/de_CH/downloads/safari"
          class="underline"
          target="_blank">Safari</a>
        oder
        <a
          href="https://www.microsoft.com/de-de/edge"
          target="_blank"
          class="underline">Edge</a>
        ) in aktueller Version für die Bedienung voraussetzt. Internet Explorer
        werden ausdrücklich nicht unterstützt!
      </div>

      <div
        v-if="old_browser"
        class="border-4 p-2 border-red-700 mt-10 text-sm font-semibold">
        Sie verwenden einen alten Browser den wir nicht unterstützen!
        Installieren Sie bitte einen aktuellen, zeitgemässen und sicheren
        Browser in einer aktuellen Version.
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { useFocus } from '@vueuse/core'

export default {
  data() {
    return {
      old_browser: false,
      email: '',
      token: ''
    }
  },
  computed: {
    ...mapGetters(['auth','session', 'login_state_email', 'login_state_token']),
  },
  mounted() {
    this.clear_login()
    this.old_browser = !('Promise' in window)

    useFocus(this.$refs.email, { initialValue: true })
  },
  methods: {
    ...mapActions(['send_login_email', 'send_login_token', 'clear_login']),
    submit_form(e) {
      e.preventDefault()
      if (this.login_state_email && this.session.id > '') {
        this.send_login_token(this.token)
      } else {
        this.token = ''
        this.send_login_email(this.email)
        useFocus(this.$refs.token)
      }
      return false
    }
  }
}
</script>

<style>
@media (min-width: 640px) {
  .login-window {
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.31);
  }
}
</style>
