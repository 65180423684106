<template>
  <div class="mt-10 text-sm">
    <h2 class="mb-1 font-semibold">Erläuterung der Funktionen</h2>
    <div class="mt-2 flex">
      <div class="mr-4">
        <div class="flex gap-2 mb-1 items-start">
          <button
            class="shadow rounded-sm w-6 px-1 py-1 bg-blue-500 text-xs text-white mr-1"
          >
            <font-awesome-icon icon="plus" />
          </button>
          Neuer Antrag
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <button
            class="shadow rounded-sm w-6 px-1 py-1 bg-green-500 text-xs text-white mr-1"
          >
            <font-awesome-icon icon="edit" />
          </button>
          Antrag bearbeiten
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <button
            class="shadow rounded-sm w-6 px-1 py-1 bg-gray-600 text-xs text-white mr-1"
          >
            <font-awesome-icon icon="file-alt" />
          </button>
          Daten anzeigen
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <button
            class="shadow rounded-sm w-6 px-1 py-1 bg-blue-500 text-xs text-white mr-1"
          >
            <font-awesome-icon icon="money-check" />
          </button>
          Stromrechnung für Monat/Quartal
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <button
            class="shadow rounded-sm w-6 px-1 py-1 bg-red-700 text-xs text-white mr-1"
          >
            <font-awesome-icon icon="trash" />
          </button>
          Antrag löschen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
