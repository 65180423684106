<template>
  <div class="mt-12 mb-8 text-sm font-normal">
    <h1 class="mt-4 text-xl flex justify-between">
      <span>Erfasste EKZ Anträge</span>
      <button
        v-if="debug"
        @click="debug_switch"
        class="text-sm px-2 py-1 text-white rounded bg-red-500"
      >
        <font-awesome-icon icon="bug" />
      </button>
    </h1>

    <div
      v-if="false"
      class="border border-dashed flex gap-2 flex-wrap flex-row p-5 w-full"
    >
      <div
        v-for="i in 5"
        :key="i"
        class="border px-4 py-2 h-20 w-32 flex flex-col justify-between items-center">
        <img src="../images/pdf.png" class="w-10" alt="allowed data type png" />
        <div>Name</div>
      </div>
    </div>

    <div class="mt-2">
      <p class="">
        In dieser Übersicht sehen Sie alle Ihre erfassten Anträge. Nach der Erfassung und
        anschliessender Übermittlung an das Amt für Volkswirtschaft (AVW) ist nur noch
        die Anzeige Ihres Antrags möglich. Den aktuellen Bearbeitungsstand wird Ihnen
        laufend angezeigt.
      </p>

      <info-box />

      <div id="proposals" class="mt-8 px-2 rounded">
        <div class="header flex gap-1 py-2">
          <div v-if="false" class="w-1/12 ml-2 self-end">ID</div>
          <div class="w-5/12 font-semibold">
            Firma
            <br />Bearbeitung
          </div>
          <div class="w-5/12 font-semibold">
            Plz Ort
            <br />Strasse
          </div>
          <div class="w-2/12 text-right"></div>
        </div>

        <div
          v-for="(proposal, idx) in proposals"
          :key="proposal.id"
          class="mb-4 border-b-2 border shadow-sm p-2 py-2 pr-2 hover:bg-gray-200 bg-gray-100"
          :xclass="{ 'bg-gray-200': idx % 2 == 0 }"
        >
          <div v-if="display_debug">
            {{ proposal }}
          </div>

          <div class="flex justify-between">
            <div v-if="proposal.status == ProposalStatus.STATUS_IS_ACCEPTED ||
                       ( proposal.status >= ProposalStatus.STATUS_IS_OPEN &&
                         proposal.status < ProposalStatus.STATUS_IS_REFUSED
                       )">
              <span class="w-full ml-1 mt-2 mb-2 font-semibold">
                Eingereichter Antrag [{{ proposal.token }}]
              </span>
            </div>

            <div v-else-if="proposal.status == ProposalStatus.STATUS_IS_NOT_RELEASED">
              <span class="w-full ml-1 mt-2 mb-2 font-semibold bg-yellow-200 bg-opacity-50">
                Antrag ist noch nicht eingereicht
              </span>
            </div>

            <button
              v-if="with_share"
              @click="open_share_window(proposal.id)"
              class="px-2 py-1 text-sm rounded text-white bg-blue-200 hover:bg-blue-500"
              title="Diesen Antrag mit einem/oder mehreren anderen Benutzer/n teilen"
            >
              <font-awesome-icon icon="share" />
            </button>
          </div>

          <div class="flex items-baseline gap-1 p-1">
            <div class="w-5/12">
              <div class="flex gap-1">
                <div
                  class="flex justify-center items-center w-5 h-5 mr-1 font-semibold text-xs"
                  :class="get_class(proposal.status)"
                  :title="get_class_title(proposal.status)"
                >
                  {{ get_class_content(proposal.status) }}
                </div>
                {{ proposal.company_name || String(proposal.id).split(/-/)[0] }}
              </div>
              <small>{{ moment(proposal.created_at).format("D.M.Y, H:mm") }}</small>
            </div>

            <div class="w-5/12">
              {{ current_city(proposal.company_city_id) }}<br>
              {{ proposal.company_street }}
            </div>

            <div
              v-if="proposal.status !== ProposalStatus.STATUS_IS_STORNO"
              class="w-2/12 pr-0 text-right self-center gap-1 flex justify-end"
            >
              <button
                @click="open_edit(proposal.id)"
                class="px-2 py-1 text-sm rounded"
                title="Bearbeiten oder Antragsdaten anzeigen"
                :class="{
                  'text-white bg-green-500 hover:bg-green-700': proposal.status < ProposalStatus.STATUS_IS_OPEN,
                  'text-gray-700 border border-gray-300 hover:bg-gray-800 hover:text-white': proposal.status >= ProposalStatus.STATUS_IS_OPEN,
                }"
              >
                <font-awesome-icon v-if="proposal.status < ProposalStatus.STATUS_IS_OPEN" icon="edit" />
                <font-awesome-icon v-else icon="file-alt" />
              </button>

              <button
                v-if="proposal.status < ProposalStatus.STATUS_IS_OPEN"
                @click="delete_proposal(proposal.id)"
                class="px-2 py-1 bg-red-700 hover:bg-red-800 text-sm rounded text-white"
                title="Antrag löschen"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>

          <!-- Turnover Figures -->
          <template
            v-if="
              proposal.status == ProposalStatus.STATUS_IS_ACCEPTED &&
              proposal.turnover_figure_proposals &&
              proposal.turnover_figure_proposals.length > 0
            "
          >
            <h3 class="font-medium flex mb-1 ml-12">
              1. Eingereichte Umsatzzahlen/Antrag für 2021 / Status
            </h3>

            <div
              v-for="(tfp, idx) in proposal.turnover_figure_proposals"
              :key="`turnover_figure_${idx}`"
              class="flex mb-1 ml-11 justify-center align-middle hover:bg-gray-200"
            >
              <div class="w-10/12 flex self-center">
                <div
                  class="mr-1 flex justify-center items-center w-5 h-5 font-semibold text-xs"
                  :class="get_turnover_figure_class(tfp.status)"
                >
                  {{ get_turnover_figure_content(tfp.status) }}
                </div>
                <span
                  v-if="tfp.status == -1"
                  class="bg-yellow-200 px-2 bg-opacity-50"
                >
                  Die Umsatzzahlen/Antrag wurden noch nicht eingereicht
                  <small>({{ formatDate(tfp.created_at) }})</small>
                </span>
                <span v-else-if="tfp.status < 99" class="bg-yellow-200 px-1">
                  {{ get_turnover_figure_title(tfp.status) }}
                  <small>({{ formatDate(tfp.created_at) }})</small>
                </span>
                <span v-else class="px-1">
                  {{ get_turnover_figure_title(tfp.status) }}
                  <small>({{ formatDate(tfp.created_at) }})</small>
                </span>
              </div>

              <div class="w-2/12 text-right -ml-1">

                <button
                  v-if="tfp.status >= 0"
                  @click="open_turnover_figure_window(proposal.id, tfp.id)"
                  class="px-2 py-1  text-sm rounded border text-gray-700 border-gray-300 hover:bg-gray-700 hover:text-white"
                  title="Umsatzzahlen / Steuerangaben bearbeiten"
                >
                  <font-awesome-icon icon="file-alt" />
                </button>

                <button
                  v-if="tfp.status < 0"
                  @click="open_turnover_figure_window(proposal.id, tfp.id)"
                  class="px-2 py-1 bg-green-500 hover:bg-green-700 text-sm rounded text-white"
                  title="Umsatzzahlen / Steuerangaben bearbeiten"
                >
                  <font-awesome-icon icon="edit" />
                </button>
                <button
                  v-if="tfp.status < 0"
                  @click="delete_turnover_figure_proposal(tfp.id)"
                  class="ml-1 px-2 py-1 bg-red-700 hover:bg-red-800 text-sm rounded text-white"
                  title="Umsatzzahlen / Steuerangaben löschen"
                >
                  <font-awesome-icon icon="trash" />
                </button>

              </div>
            </div>

            <div v-if="!open_or_accepted_turnover_figure_proposal(proposal)" class="w-full flex justify-end">
              <button
                @click="open_turnover_figure_window(proposal.id, null)"
                class="text-sm mr-1 px-2 py-1 bg-blue-500 hover:bg-blue-700 text-white rounded"
                title="Neue Umsatzzahlen für die STV einrichten">
                <font-awesome-icon class="mr-1" icon="plus" />Neue Umsatzzahlen einreichen
              </button>
            </div>

          </template>

          <!-- Display Bills -->
          <template v-if="proposal?.bills?.length > 0">
            <div class="mt-4">
              <h3 class="font-medium flex mb-1 ml-12">2. Eingereichte Rechnungen / Status</h3>
              <div
                v-for="bill in proposal.bills"
                :key="bill.id"
                class="flex mb-1 ml-10 justify-center align-middle hover:bg-gray-200">

                <div class="w-10/12 flex self-center">
                  <div
                    class="ml-1 mr-1 flex justify-center items-center w-5 h-5 font-semibold text-xs"
                    :class="get_turnover_figure_class(bill.status)"
                  >
                    {{ get_turnover_figure_content(bill.status) }}
                  </div>
                  <span
                    v-if="bill.status == -1"
                    class="bg-yellow-200 px-2 bg-opacity-50"
                  >
                    Unterlagen für {{ payment_period(bill.payment_period_id)?.year }} /
                    {{ only_last_active_turnover_figure_proposal(proposal)?.energy_bill_period == 1 ? 'Q' : '' }}{{ bill.month }} wurden noch nicht eingereicht
                    <small>({{ formatDate(bill.created_at) }})</small>
                  </span>
                  <span v-else-if="bill.status < 99" class="bg-yellow-200 px-1">
                    Unterlagen für {{ payment_period(bill.payment_period_id)?.year }} /
                    {{ only_last_active_turnover_figure_proposal(proposal)?.energy_bill_period == 1 ? 'Q' : '' }}{{ bill.month }} werden noch geprüft
                    <small>({{ formatDate(bill.created_at) }})</small>
                  </span>
                  <span v-else-if="bill.status == 100" class="px-1">
                    Bearbeitung für {{ payment_period(bill.payment_period_id)?.year }} /
                    {{ only_last_active_turnover_figure_proposal(proposal)?.energy_bill_period == 1 ? 'Q' : '' }}{{ bill.month }} ist abgeschlossen
                    <small>({{ formatDate(bill.created_at) }})</small>
                  </span>
                  <span v-else-if="bill.stats == 199 || bill.status == 99" class="px-1">
                    Unterlagen für {{ payment_period(bill.payment_period_id)?.year }} /
                    {{ only_last_active_turnover_figure_proposal(proposal)?.energy_bill_period == 1 ? 'Q' : '' }}{{ bill.month }} wurden abgelehnt
                    <small>({{ formatDate(bill.created_at) }})</small>
                  </span>
                  <span v-else class="px-1">
                    Unterlagen für {{ payment_period(bill.payment_period_id)?.year }} /
                    {{ only_last_active_turnover_figure_proposal(proposal)?.energy_bill_period == 1 ? 'Q' : '' }}{{ bill.month }} in arbeit
                    <small>({{ formatDate(bill.created_at) }})</small>
                  </span>
                </div>

                <div class="w-2/12 text-right -ml-1">
                  <button
                    v-if="bill.status >= 0"
                    @click="open_bill_upload_window(proposal.id, bill?.payment_period_id, bill?.payment_period?.year, bill.month)"
                    class="px-2 py-1  text-sm rounded border text-gray-700 border-gray-300 hover:bg-gray-700 hover:text-white"
                    title="Rechnung anzeigen"
                  >
                    <font-awesome-icon icon="file-alt" class="text-inherit" />
                  </button>

                  <button
                    v-if="bill.status < 0"
                    @click="open_bill_upload_window(proposal.id, bill?.payment_period_id, bill?.payment_period?.year, bill.month)"
                    class="px-2 py-1 bg-green-500 hover:bg-green-700 text-sm rounded text-white"
                    title="Rechnung bearbeiten"
                  >
                    <font-awesome-icon icon="edit" />
                  </button>
                  <button
                    v-if="bill.status < 0"
                    @click="delete_bill({ proposal_id: proposal.id, id: bill.id })"
                    class="ml-1 px-2 py-1 bg-red-700 hover:bg-red-800 text-sm rounded text-white"
                    title="Rechnung löschen"
                  >
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>
            </div>
          </template>
          <!-- end Display Bills -->

          <!-- Buttons vor Bills -->
          <div
            v-if="
              proposal.status == ProposalStatus.STATUS_IS_OPEN ||
              proposal.status == ProposalStatus.STATUS_IS_VALID ||
              proposal.status == ProposalStatus.STATUS_IS_ACCEPTED"
               class="flex mt-4 ml-12 mb-1 mr-1 gap-2 items-center justify-between">

            <div>
              Drücken Sie den Button für die passende Rechnungsperiode und laden<br>
              Sie die Rechnung/Dokumente Ihres Energieversorgers hoch.
            </div>

            <div v-for="pp in payment_periods" :key="pp.id">
              <div v-if="pp.is_valid" class="flex gap-1 flex-wrap justify-end">

                <template v-if="only_last_active_turnover_figure_proposal(proposal)?.energy_bill_period == 1">
                  <button
                    v-if="missing_or_rejected_bill(proposal, pp, pp.month)"
                    @click="open_bill_upload_window(proposal.id, pp.id, pp.year, pp.month, true)"
                    class="px-2 py-1 text-sm rounded text-white bg-blue-500"
                    :title="`Umsatzzahlen für das Quartal ${pp.month} erfassen`"
                  >
                    <font-awesome-icon icon="money-check" />
                    {{ pp.year }} / Q{{ pp.month }}
                    </button>
                </template>

                <template
                  v-if="only_last_active_turnover_figure_proposal(proposal)?.energy_bill_period == 2"
                  v-for="month in 3"
                  :key="month"
                >

                  <button
                    v-if="missing_or_rejected_bill(proposal, pp, 3 * (pp.month - 1) + month)"
                    @click="open_bill_upload_window(proposal.id, pp.id, pp.year, 3 * (pp.month - 1) + month, true)"
                    class="px-2 py-1 text-sm rounded text-white bg-blue-500"
                    :title="`Umsatzzahlen für den Monat ${3 * ( pp.month - 1 ) + month} erfassen`"
                  >
                    <font-awesome-icon icon="money-check" />
                    {{ pp.year }} / {{ 3 * (pp.month - 1) + month }}
                  </button>

                </template>
              </div>
            </div>
          </div>

          <!-- Payments -->
          <template
            v-if="
              proposal.status == ProposalStatus.STATUS_IS_ACCEPTED &&
              proposal.payments &&
              proposal.payments.length > 0
            "
          >
            <div
              v-for="(ap, idx) in proposal.payments"
              :key="idx"
              class="w-full p-1 flex gap-1"
            >
              <div
                class="flex justify-center items-center w-5 h-5 mr-1 font-semibold text-xs bg-green-700 text-white"
              >
                O
              </div>
              <span class="flex-grow">
                Die Auszahlung von {{ ap.payout }} CHF {{ ap.purpose }}
                wurde freigegeben.
              </span>
            </div>
          </template>

        </div>
      </div>
    </div>

    <div class="flex mt-8 justify-between">
      <div>
        <button
          @click="open_ask_proposal_window"
          class="mr-1 px-3 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded"
          title="Erstellen Sie einen neuen Antrag. Geben Sie alle Felder selbst ein.">
          <font-awesome-icon class="mr-1" icon="plus" />Neuer Antrag
        </button>
      </div>

      <button
        @click="logout"
        class="px-3 py-2 bg-gray-600 hover:bg-gray-800 text-white rounded shadow">
        Abmelden
        <font-awesome-icon class="ml-1" icon="door-closed" />
      </button>
    </div>

    <div class="flex justify-between">
      <status-colors />
      <button-help />
    </div>
  </div>

</template>

<script>
import EventBus from '../utils/event-bus'
import InfoBox from '../components/info-box.vue'
import StatusColors from '../components/status_colors.vue'
import ButtonHelp from '../components/button_help.vue'
import ProposalStatus from '../utils/proposal-contants'

import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  props: {
    debug: {
      type: Boolean,
      default: false
    },
    display_debug: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      with_share: false,
      ProposalStatus,
      statuscodes: {
        // -1:  { class: "bg-blue-300", content: "-" },
        0: {
          class: "bg-yellow-500",
          content: "B",
          title: "Der Antrag ist in Bearbeitung",
        },
        10: {
          class: "bg-yellow-500",
          content: "B",
          title: "Der Antrag ist in Bearbeitung",
        },
        20: {
          class: "bg-yellow-500",
          content: "B",
          title: "Der Antrag ist in Bearbeitung",
        },
        30: {
          class: "bg-orange-500 text-white",
          content: "K",
          title: "Die Antragsdaten werden kontrolliert",
        },
        40: {
          class: "bg-orange-500 text-white",
          content: "K",
          title: "Die Antragsdaten werden kontrolliert",
        },
        98: {
          class: "bg-orange-500 text-white",
          content: "K",
          title: "Die Antragsdaten werden kontrolliert",
        },
        99: {
          class: "bg-red-700 text-white",
          content: "X",
          title: "Der Antrag wurde abgelehnt",
        },
        100: {
          class: "bg-green-700 text-white",
          content: "O",
          title: "Der Antrag wurde akzeptiert",
        },
        199: {
          class: "bg-red-700 text-white",
          content: "S",
          title: "Der Antrag wurde storniert",
        },
      },
      turnover_figure_statuscodes: {
        null: {
          class: "bg-green-500 text-white",
          content: "A",
          title: "Die Umsatzzahlen sind in Bearbeitung",
        },
        0: {
          class: "bg-blue-500 text-white",
          content: "F",
          title: "Die Umsatzzahlen sind in Bearbeitung",
        },
        20: {
          class: "bg-blue-500 text-white",
          content: "F",
          title: "Die Umsatzzahlen sind in Bearbeitung",
        },
        99: {
          class: "bg-red-700 text-white",
          content: "X",
          title: "Die Umsatzzahlen wurden abgelehnt",
        },
        100: {
          class: "bg-green-700 text-white",
          content: "O",
          title: "Die Umsatzzahlen wurden genehmigt",
        },
        190: {
          class: "bg-red-700 text-white",
          content: "C",
          title: "Ein Verstoss gegen die Richtlinien",
        },
        199: {
          class: "bg-red-700 text-white",
          content: "S",
          title: "Die Umsatzzahlen wurden storniert",
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      "proposals",
      "auth",
      "current_city",
      "cities",
      "city",
      "payment_periods",
      "payment_period",
    ]),
    missing_or_rejected_bill() {
      return (proposal, payment_period, period) => {
        const bill = proposal?.bills?.find((b) => {
          return b.month == period &&
                 b.payment_period_id == payment_period.id &&
                 ( b.status == -1 || b.status == 0 || b.status == 20 || b.status == 100 )
        })
        if (bill) return false
        return true
      }
    },
    open_or_accepted_turnover_figure_proposal() {
      return (proposal) => {
        const ret = proposal.turnover_figure_proposals.find((e) => {
          if ( e.status == -1 || e.status == 0 || e.status == 20 || e.status == 100 )
            return true
        })
        if (ret) return true
        else return false
      }
    }
  },
  methods: {
    ...mapActions([
      "get_proposals",
      "new_proposal",
      "delete_proposal",
      "delete_turnover_figure_proposal",
      'delete_bill'
    ]),
    formatDate(date) {
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'Europe/Zurich'
      }

      try {
        const res = new Intl.DateTimeFormat('de-CH', options).format(Date.parse(date))
        return res
      } catch(error) {
        return date
      }
    },
    moment(field) {
      return moment(field)
    },
    only_last_active_turnover_figure_proposal(proposal) {
      const ret = proposal.turnover_figure_proposals.find((e) => {
        if (
          e.status == -1 ||
          e.status == 0 ||
          e.status == 20 ||
          e.status == 99 ||
          e.status == 100
        )
          return true
      })
      return ret
    },
    banking_payment_found(proposal_id, payment_period_id) {
      // console.log("banking_payment_found ", proposal_id, payment_period_id)
      let proposal = this.proposals.find((p) => p.id == proposal_id)
      if (!proposal || !proposal.payments) return false

      // console.log("proposal: ", proposal)
      let banking = proposal.payments.find(
        (p) => p.payment_period_id == payment_period_id
      )
      // console.log("banking: ", banking)
      if (banking) {
        return true
      } else {
        return false
      }
    },
    check_missing_turnover_figures(proposal) {
      console.log(
        'overview: turnover_figure_proposals: ',
        proposal.turnover_figure_proposals
      )
      if (proposal.status < 99) return false
      if (proposal.turnover_figure_proposals.length <= 0) return true

      const payment_period_ids = this.payment_periods.map((p) => p.id)
      const used_payment_period_ids = [
        // to get an unique list
        ...new Set(
          proposal.turnover_figure_proposals.map((p) => p.payment_period_id)
        ),
      ]
      console.log(
        'payment_period_ids',
        payment_period_ids,
        'used_payment_period_ids',
        used_payment_period_ids
      )
      if (
        payment_period_ids.length != used_payment_period_ids.length ||
        !used_payment_period_ids.every((v) => payment_period_ids.includes(v))
      ) {
        return true
      }

      const ppi_cnt = payment_period_ids.map((ppi) => {
        return proposal.turnover_figure_proposals
          .filter((e) => e.payment_period_id === ppi)
          .every((e) => e.status == -1 || e.status == 99 || e.status == 199)
      })
      console.log('ppi_cnt:', ppi_cnt)
      const s = ppi_cnt.every((e) => e === false)
      return !s
    },
    debug_switch() {
      EventBus.$emit('debug-switch')
    },
    open_share_window(proposal_id) {
      EventBus.$emit('open-share-window', proposal_id)
    },
    open_ask_proposal_window() {
      EventBus.$emit('open-ask-new-proposal-window')
    },
    open_turnover_figure_window(proposal_id, turnover_figure_proposal_id) {
      EventBus.$emit('open-turnover-figure-window', {
        proposal_id: proposal_id,
        turnover_figure_proposal_id: turnover_figure_proposal_id
      })
    },
    open_bill_upload_window(proposal_id, payment_period_id, year, month, modus) {
      console.log('before emit open-bill-upload-window')
      EventBus.$emit('open-bill-upload-window', {
        proposal_id,
        payment_period_id,
        year,
        month,
        modus
      })
    },
    get_turnover_figure_class(status) {
      if (status == -1) {
        return "bg-blue-300 text-black"
      }
      if (this.turnover_figure_statuscodes[status]) {
        return this.turnover_figure_statuscodes[status].class
      } else {
        ;("bg-red-700")
      }
    },
    get_turnover_figure_content(status) {
      if (status == -1) {
        return "-"
      }
      if (this.turnover_figure_statuscodes[status]) {
        return this.turnover_figure_statuscodes[status].content
      } else {
        return "E"
      }
    },
    get_turnover_figure_title(status) {
      if (status == -1) {
        return "-"
      }
      if (this.turnover_figure_statuscodes[status]) {
        return this.turnover_figure_statuscodes[status].title
      } else {
        return "E"
      }
    },
    get_class(proposal_status) {
      if (proposal_status == -1) {
        return "bg-blue-300"
      }
      if (this.statuscodes[proposal_status]) {
        return this.statuscodes[proposal_status].class
      } else {
        ;("bg-red-700")
      }
    },
    get_class_title(status) {
      if (status == -1) {
        return "-"
      }
      if (this.statuscodes[status]) {
        return this.statuscodes[status].title
      } else {
        return "E"
      }
    },
    get_class_content(status) {
      if (status == -1) {
        return "-"
      }
      if (this.statuscodes[status]) {
        return this.statuscodes[status].content
      } else {
        return "E"
      }
    },
    get_city_name(id) {
      return this.city(id).name
    },
    open_edit(id) {
      EventBus.$emit("open-edit", id)
    },
    request_new_proposal() {
      this.new_proposal().then((response) => {
        // console.log("login:new_proposal", response)
        EventBus.$emit("open-edit", response.id)
      })
    },
    logout() {
      EventBus.$emit("logout")
    },
  },
  async mounted() {
    // await this.get_proposals()
  },
  components: {
    InfoBox,
    StatusColors,
    ButtonHelp
  },
}
</script>
