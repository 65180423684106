import { createStore } from 'vuex'

import Api from '../utils/api'

export const store = createStore({
  plugins: [],
  state: {
    version: {},
    init_status: false,
    errors: null,
    success: null,
    auth: {
      login_state_email: false,
      login_state_token: false,
      session: {},
    },
    transfer_message: null,
    commercial_types: [],
    cities: [],
    countries: [],
    proposals: [],
    payment_periods: [],
    proposal_fields: [],
  },
  getters: {
    auth: (state) => state.auth,
    errors: (state) => state.errors,
    success: (state) => state.success,
    init_status: (state) => state.init_status,
    session: (state) => state.auth.session,
    login_state_email: (state) => state.auth.login_state_email,
    login_state_token: (state) => state.auth.login_state_token,
    logged_in: (state) =>
      state.auth.login_state_email && state.auth.login_state_token,
    proposals: (state) => state.proposals,
    countries: (state) => state.countries,
    payment_periods: (state) => state.payment_periods,
    payment_period: (state) => (id) =>
      state.payment_periods.find((p) => p.id === id),
    cities: (state) => state.cities,
    city: (state) => (id) => state.cities.find((c) => c.id === id),
    commercial_types: (state) => state.commercial_types,
    current_city: (state) => (key) => {
      if (key <= "" || state.cities.length <= 0) return ""
      const c = state.cities.find((e) => e.id == key)
      if (c) {
        return c["zipcode"] + " " + c["name"]
      }
    },
    reopening_from_branch: (state) => (key) => {
      if (key <= "" || state.commercial_types.length <= 0) return ""
      const c = state.commercial_types.find((e) => e.id == key)
      if (c) return c["reopened_at"]
    },
    display_errors: (state) => {
      let str = []
      // console.log(state.proposal_fields);
      // console.log("display_errors", state.errors["errors"]);
      if (state.errors["errors"]) {
        for (let f in state.errors["errors"]) {
          let fieldname = state.proposal_fields[f] || f
          str.push(fieldname + ": " + state.errors["errors"][f].join(", "))
        }
      }
      return str
    },
  },
  mutations: {
    version: (state, version) => state.version = version,
    proposals: (state, data) => (state.proposals = data),
    proposal_append: (state, data) => state.proposals.push(data),
    proposal_delete: (state, id) => {
      const p = state.proposals.find((e) => e.id == id)
      if (p) state.proposals.splice(state.proposals.indexOf(p), 1)
    },
    proposal_update: (state, data) => {
      let p = state.proposals.find((p) => p.id == data.id)
      if (p) {
        p.status = data.status
        p.token = data.token
      }
    },
    turnover_figure_proposal_delete: (state, data) => {
      const proposal = state.proposals.find((p) => p.id == data.proposal_id)
      if (!proposal) return false

      const tfp = proposal.turnover_figure_proposals.find(
        (p) => p.id === data.id
      )
      if (!tfp) return false

      proposal.turnover_figure_proposals.splice(
        proposal.turnover_figure_proposals.indexOf(tfp),
        1
      )
    },
    bill_delete: (state, data) => {
      const proposal = state.proposals.find((p) => p.id == data.proposal_id)
      if (!proposal) return false

      const bill = proposal.bills.find((b) => b.id == data.id)
      if (!bill) return false

      proposal.bills.splice(proposal.bills.indexOf(bill), 1)
    },
    bill_update: (state, data) => {
      console.log(
        "bill_update start",
        state.proposals,
        data.data,
        data.data.proposal_id
      )
      console.log(
        "search proposal",
        state.proposals.find((p) => p.id == data.data.proposal_id)
      )
      const proposal = state.proposals.find(
        (p) => p.id == data.data.proposal_id
      )
      if (!proposal) return

      let bill = proposal.bills.find((p) => p.id == data.data.id)
      console.log("bill_update found data?", proposal, bill)

      if (bill) {
        console.log("bill_update: set bill", data.data)
        bill.status = data.data.status
      } else {
        console.log("bill_update: add bill", data.data)
        proposal.bills.push(data.data)
      }
    },
    proposal_turnover_figure_update: (state, data) => {
      let proposal = state.proposals.find((p) => p.id == data.proposal_id)
      let turnover_figure_proposal = proposal.turnover_figure_proposals.find(
        (p) => p.id == data.id
      )
      console.log(
        "proposal_turnover_figure_update",
        proposal,
        turnover_figure_proposal
      )
      if (!turnover_figure_proposal) {
        let turnover_figure_proposal =
          data.proposal.turnover_figure_proposals.find((p) => p.id == data.id)
        console.log("turn over found", turnover_figure_proposal)
        if (turnover_figure_proposal) {
          proposal.turnover_figure_proposals.push(turnover_figure_proposal)
        }
      }
    },
    proposal_turnover_figure_update_loss: (state, data) => {
      let proposal = state.proposals.find((p) => p.id == data.proposal_id)
      let turnover_figure_proposal = proposal.turnover_figure_proposals.find(
        (p) => p.id == data.id
      )
      if (turnover_figure_proposal) {
        turnover_figure_proposal["calc_loss"] = data.data.calc_loss
      }
    },
    proposal_turnover_figure_release: (state, data) => {
      // console.log("proposal_turnover_figure_release", data);
    },
    cities: (state, data) => (state.cities = data),
    commercial_types: (state, data) => (state.commercial_types = data),
    countries: (state, data) => (state.countries = data),
    payment_periods: (state, data) => (state.payment_periods = data),
    login_state_email: (state, data) => (state.auth.login_state_email = data),
    login_state_token: (state, data) => (state.auth.login_state_token = data),
    session_store: (state, data) => (state.auth.session = data),
    transfer_message: (state, data) => (state.transfer_message = data),
    clear_session: (state) => {
      state.auth.session = {}
      state.auth.login_state_email = false
      state.auth.login_state_token = false
    },
    init_start: (state) => (state.init_status = false),
    init_done: (state) => (state.init_status = true),
    set_success: (state, data) => (state.success = data),
    clear_success: (state) => (state.success = null),
    set_errors: (state, data) => (state.errors = data),
    clear_errors: (state) => (state.errors = null),
  },
  actions: {
    async clear_login(context) {
      context.commit("clear_session")
    },
    async get_version(context) {
      return await Api()
        .get(`version`)
        .then((response) => {
          context.commit("version", response.data)
          return response.data
        })
        .catch((error) => {
          // console.log("store::check_auth error");
        })
    },
    async check_auth(context) {
      return await Api()
        .get(`auths/check`)
        .then((response) => {
          context.commit("session_store", response.data.session)
          context.commit("login_state_token", true)
          context.commit("login_state_email", true)
          return response.data
        })
        .catch((error) => {
          // console.log("store::check_auth error");
        })
    },
    async get_basedata(context) {
      return await Api()
        .get(`basedata`)
        .then((response) => {
          // console.log("get_basedata", response.data);
          context.commit("cities", response.data.cities)
          context.commit("commercial_types", response.data.commercial_types)
          context.commit("countries", response.data.countries)
          context.commit("payment_periods", response.data.payment_periods)
          return response.data
        })
        .catch((error) => {
          // console.log("store::get_basedata error");
        })
    },
    async get_proposals(context) {
      return await Api()
        .get(`proposals`)
        .then((response) => {
          context.commit("proposals", response.data)
          return response.data
        })
        .catch((error) => {
          // console.log("store::get_proposals error");
        })
    },
    async request_extend_proposal_period(context, data) {
      // console.log("request_extend_proposal_period", data);
      return await Api()
        .post(`proposals/extend_payment_period`, {
          proposal_id: data.proposal_id,
          payment_period_id: data.payment_period_id,
          comment: data.extend_period_comment,
        })
        .then((response) => {
          // console.log( "request_extend_proposal_period;::response", response.data );
          // context.commit("payment_periods", response.data.payment_periods);
          context.dispatch("get_basedata")
          context.dispatch("get_proposals")
          return response.status
        })
        .catch((error) => {
          // console.log("error: store_extend_payment_period", error);
          // throw new Error(error.response)
          // return Promise.reject(error.response);
          return Promise.reject(error)
        })
    },
    async logout(context) {
      return await Api()
        .delete(`customer_sessions/${context.getters.session.id}`)
        .then((response) => {
          // console.log("logout", response.data);
          context.commit("clear_session")
          return context.getters.auth
        })
        .catch((error) => {
          // console.log("store::logout error");
        })
    },
    async send_login_email(context, email) {
      return await Api()
        .post("customer_sessions", {
          email: email,
        })
        .then((response) => {
          // console.log("send_login_email", response.data);
          context.commit("session_store", response.data.session)
          context.commit("login_state_email", true)
          // console.log("send_login_email", context.getters.auth);
          return context.getters.auth
        })
        .catch((error) => {
          // console.log(error);
          context.commit(
            "transfer_message",
            "Ihr Login schlug fehlt. Bitte versuchen Sie es später nochmal."
          )
        })
    },
    async send_login_token(context, token) {
      return await Api()
        .patch(`customer_sessions/${context.getters.session.id}`, {
          id: context.getters.session.id,
          token: token,
        })
        .then((response) => {
          // console.log("send_login_token", response.data);
          context.commit("session_store", response.data.session)
          context.commit("login_state_token", true)
          return context.getters.auth
        })
        .catch((error) => {
          // console.log(error);
          context.commit("clear_session")
          context.commit(
            "transfer_message",
            "Ihr Login schlug fehlt. Bitte versuchen Sie es erneut."
          )
        })
    },
    async delete_turnover_figure_proposal(context, id) {
      return await Api()
        .delete(`turnover_figure_proposals/${id}`)
        .then((response) => {
          // console.log("delete_proposal::response", response.data);
          context.commit("turnover_figure_proposal_delete", response.data)
        })
        .catch((error) => {
          // console.log("store::delete_proposal error", error);
        })
    },
    async delete_proposal(context, id) {
      return await Api()
        .delete(`proposals/${id}`)
        .then((response) => {
          // console.log("delete_proposal::response", response.data);
          context.commit("proposal_delete", id)
        })
        .catch((error) => {
          // console.log("store::delete_proposal error", error);
        })
    },
    async new_proposal(context) {
      console.log("new_proposal::response")
      return await Api()
        .post(`proposals`)
        .then((response) => {
          // console.log("new_proposal::response", response.data.proposal);
          context.commit("proposal_append", response.data.proposal)
          return response.data.proposal
        })
        .catch((error) => {
          // console.log("store::new_proposal error", error);
        })
    },
    async release_turnover_figure_proposal(context, data) {
      const params = {
        id: data.id,
        proposal_id: data.proposal_id,
      }
      return await Api()
        .post(`turnover_figure_proposals/release`, params)
        .then((response) => {
          console.log(
            "release_turnover_figure_proposal::response",
            response.data
          )
          // context.commit("proposal_turnover_figure_release", response.data);
          return response
        })
        .catch((error) => {
          console.log("store::release_turnover_figure_proposal error", error)
          return error
        })
    },
    async update_turnover_figure_proposal(context, data) {
      const model = data.model || "turnover_figure_proposal"
      const params = {
        id: data.id,
        proposal_id: data.proposal_id,
        [model]: {
          ...data.data,
        },
      }
      return await Api()
        .post(`turnover_figure_proposals`, params)
        .then((response) => {
          console.log(
            "update_turnover_figure_proposal::response",
            response.data,
            data
          )
          context.commit("proposal_turnover_figure_update", response.data)
          if (data.data.calc_loss) {
            context.commit("proposal_turnover_figure_update_loss", data)
          }
          return response
        })
        .catch((error) => {
          console.log("store::update_turnover_figure_proposal error", error)
        })
    },
    async update_proposal(context, data) {
      const model = data.model || "proposal"
      const params = {
        id: data.id,
        [model]: {
          // id: data.id,
          ...data.data,
        },
      }
      return await Api()
        .post(`proposals`, params)
        .then((response) => {
          console.log("update_proposal::response", response.data)
          context.commit("proposal_update", response.data)
        })
        .catch((error) => {
          // console.log("store::update_proposal error", error);
        })
    },
    async release_proposal(context, id) {
      context.state.errors = null
      context.state.success = null
      // console.log("release_proposal", id);
      return await Api()
        .post(`proposals/release`, {
          id: id,
        })
        .then((response) => {
          // console.log("release_proposal::response", response.data);
          context.commit("proposal_update", response.data)
          context.commit("set_success", true)
        })
        .catch((error) => {
          context.commit("set_errors", error.response.data)
          // console.log("store::release_proposal error", error);
        })
    },
    async update_bill(context, data) {
      console.log("update_bill inside vuex", data)
      const model = data.model || "bill"
      const params = {
        id: data.id,
        [model]: {
          ...data.data,
        },
      }
      if (data.id) {
        return await Api()
          .patch(`bills/${data.id}`, params)
          .then((response) => {
            console.log("update_bill:response", response.data)
            context.commit("bill_update", response.data)
            return response.data
          })
          .catch((error) => {
            console.log("store::update_bill error", error)
            return error
          })
      } else {
        return await Api()
          .post(`bills`, params)
          .then((response) => {
            console.log("update_bill:response", response.data)
            context.commit("bill_update", response.data)
            return response.data
          })
          .catch((error) => {
            console.log("store::update_bill error", error)
            return error
          })
      }
    },
    async delete_bill(context, data) {
      return Api()
        .delete(`bills/${data.id}?proposal_id=${data.proposal_id}`)
        .then(() => {
          context.commit("bill_delete", data)
        })
        .catch((error) => {
          console.log("store:delete_bill", error)
        })
    },
    async release_bill(context, data) {
      context.state.errors = null
      context.state.success = null
      return await Api()
        .post(`bills/${data.id}/release`, {
          id: data.id,
          proposal_id: data.proposal_id,
        })
        .then((response) => {
          console.log("release_bill::response", response.data)
          context.commit("bill_update", response.data)
          context.commit("set_success", true)

          return response
        })
        .catch((error) => {
          context.commit("set_errors", error)
          console.log("store::release_bill error", error)
        })
    },
    async get_shares(context, proposal_id) {
      return await Api()
        .get(`proposals/${proposal_id}/shares`)
        .then((response) => {
          return response.data
        })
        .catch((error) => {
          console.log("get_shares error")
          return error
        })
    },
    async write_share(context, data) {
      context.state.errors = null
      context.state.success = null
      return await Api()
        .post(`proposals/${data.proposal_id}/shares`, {
          proposal_id: data.proposal_id,
          email: data.email,
          mode: data.mode,
        })
        .then((response) => {
          context.commit("set_success", true)
          return response
        })
        .catch((error) => {
          context.commit("set_errors", error)
          console.log("store::release_bill error", error)
        })
    },
    async delete_share(context, data) {
      context.state.errors = null
      context.state.success = null
      return await Api()
        .delete(`proposals/${data.proposal_id}/shares/${data.id}`)
        .then((response) => {
          context.commit("set_success", true)
          return response
        })
        .catch((error) => {
          context.commit("set_errors", error)
          // console.log("store::delete_proposal error", error);
        })
    },
    async transfer_ownership(context) {},
  },
})
