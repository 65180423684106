<template>
  <div @click.self="close_window" class="fixed w-full h-full inset-0 flex z-30">
    <div class="relative rounded m-auto shadow-xl p-5 max-w-xl bg-white">
      <h1 class="font-semibold text-lg flex justify-between">
        <span>Hinweis | Bitte beachten!</span>
        <span
          @click.stop="close_window"
          class="px-2 py-1 text-xl absolute cursor-pointer top-2 right-0 -mt-2 text-gray-800 hover:bg-gray-800 hover:text-white"
        >
          <font-awesome-icon icon="times" />
        </span>
      </h1>

      <div v-if="proposals_count > 25" class="bg-yellow-200">
        <p class="mt-4 p-2">
          Es scheint, dass Sie dieses Antragssystem unüblich beanspruchen.
          Sollten Sie Verständnisprobleme haben, oder nicht weiter wissen, dann melden Sie sich bitte beim Amt für Volkswirtschaft.
        </p>
        <p class="p-2">
          Aktuell ist keine weitere Erfassung von Anträgen mit diesem Account erlaubt.
        </p>
      </div>

      <div v-else>
        <p class="mt-2 text-sm">
          Sie wollen für ein weiteres Quartal oder Monat die
          <strong>Stromrechnung hochladen</strong>, dann
          <span
            @click.stop="close_window"
            class="font-semibold cursor-pointer"
          >
            schliessen Sie dieses Fenster
          </span>
          und erfassen Sie diese unter dem genehmigten Antrag.
        </p>

        <p class="mt-5 text-sm font-bold">
          Sie haben noch keinen gültigen Antrag oder Ihr Antrag wurde abgelehnt?
        </p>

        <div class="mt-5 flex justify-end items-center">
          <button
            @click="request_new_proposal"
            class="ml-1 text-white px-3 py-2 shadow-sm bg-blue-500 rounded"
          >
            <font-awesome-icon class="mr-1" icon="plus" />Verstanden, einen neuen
            Antrag bitte
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../utils/event-bus.js'
import { mapActions } from 'vuex'

export default {
  props: {
    proposals_count: {
      type: Number,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['new_proposal']),
    close_window() {
      EventBus.$emit('close-ask-new-proposal-window')
    },
    request_new_proposal() {
      this.new_proposal().then((response) => {
        // console.log("login:new_proposal", response)
        EventBus.$emit('open-edit', response.id)
        EventBus.$emit('close-ask-new-proposal-window')
      })
    }
  },
}
</script>
