<template>
  <div class="flex gap-2 justify-end">
    <button
      @click="updateValues(valueYes)"
      class="px-2 py-0.5 rounded"
      :class="{
        'bg-gray-600 text-white': modelValue == valueYes,
        'border-2 border-gray-300': modelValue != valueYes
      }"
    >
      {{ labelYes }}
    </button>
    <button
      @click="updateValues(valueNo)"
      class="px-2 py-0.5 rounded"
      :class="{
        'bg-gray-600 text-white': modelValue == valueNo,
        'border-2 border-gray-300': modelValue != valueNo
      }"
    >
      {{ labelNo }}
    </button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  readonly: {
    type: [Number, Boolean],
    default: false
  },
  labelYes: {
    type: String,
    default: 'Ja'
  },
  labelNo: {
    type: String,
    default: 'Nein'
  },
  valueYes: { type: [Number, String], default: 1 },
  valueNo: { type: [Number, String], default: 0 },
  modelValue: {
    type: Number,
    default: null
  }
})

function updateValues(value) {
  if(props.readonly) return
  emit('update:modelValue', value)
}
</script>
