<template>
  <div id="app">
    <logo :version="version" />

    <div class="container relative max-w-3xl mx-auto">

      <spinner v-if="logged_in && !init_status" />
      <impressum v-if="display_impressum_window" />

      <login v-if="!logged_in" />

      <div v-if="logged_in && init_status">

        <transition name="fade">
          <turnover-figure-proposal-window
            v-if="display_turnover_figure_window && selected_proposal"
            :debug="debug"
            :proposal_id="selected_proposal_id"
            :turnover_figure_proposal_id="
              selected_turnover_figure_proposal_id
            "
          />
        </transition>

        <share-window v-if="display_share_window" :proposal_id="selected_proposal_id" />
        <ask-new-proposal-window v-if="display_ask_new_proposal_window" :proposals_count="proposals_count" />

        <transition name="fade">
          <bill-upload-window
            v-if="display_bill_upload_window"
            :proposal_id="selected_proposal_id"
            :payment_period_id="selected_payment_period_id"
            :year="selected_year"
            :month="selected_month"
            :modus="selected_modus"
          />
        </transition>

        <transition name="fade" xmode="in-out">
          <overview
            v-if="!display_proposal"
            class="absolute top-0 bg-white"
            :debug="debug"
            :display_debug="display_debug"
          />
        </transition>
        <transition name="fade" xmode="in-out">
          <formular
            v-if="display_proposal"
            class="absolute top-0 bg-white"
            :proposal="proposal"
            :debug="debug"
          />
        </transition>
      </div>
    </div>
    <page-footer :logged_in="logged_in" @open-impressum="display_impressum_window = true" />
  </div>
</template>

<script>
import EventBus from './utils/event-bus'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import Spinner from './components/spinner.vue'
import AskNewProposalWindow from './components/ask_new_proposal_window.vue'
import Logo from './components/logo.vue'
import PageFooter from './components/page-footer.vue'

import Login from './views/Login.vue'
import Overview from './views/Overview.vue'
import Formular from './views/Formular.vue'

import TurnoverFigureProposalWindow from "./components/turnover_figure_proposal_window.vue"
import BillUploadWindow from "./components/bill_upload_window.vue"
import ShareWindow from "./components/share_window.vue"
import Impressum from "./views/Impressum.vue"

export default {
  data() {
    return {
      debug: false,
      display_proposal: false,
      display_debug: false,
      display_impressum_window: false,
      display_bill_upload_window: false,
      display_turnover_figure_window: false,
      display_ask_new_proposal_window: false,
      display_share_window: false,
      selected_year: null,
      selected_month: null,
      selected_proposal: null,
      selected_turnover_figure_proposal: null,
      selected_proposal_id: null,
      selected_payment_period_id: null,
      selected_turnover_figure_proposal_id: null,
      selected_modus: null,
      turnover_figure_proposal: null,
      proposal: {},
      proposals_count: 0,
      window_open: false
    }
  },
  computed: {
    ...mapState([
      'version',
      'session',
      'login_state_email',
      'login_state_token',
      'proposals'
    ]),
    ...mapGetters(["logged_in", "init_status"]),
  },
  methods: {
    ...mapMutations(['clear_session', 'init_done', 'init_start']),
    ...mapActions([
      'get_version',
      'check_auth',
      'get_basedata',
      'get_proposals',
      'update_proposal',
      'update_turnover_figure_proposal',
      'new_proposal',
      'release_proposal',
      'logout'
    ]),
  },
  watch: {
    window_open: {
      handler(n, o) {
        if (n) {
          document.getElementsByTagName('body')[0].classList.add('open-window')
        } else {
          document.getElementsByTagName('body')[0].classList.remove('open-window')
        }
      }
    },
    async logged_in(v, o) {
      // console.log("logged_in changed to", v, o, this)
      if (v) {
        await this.check_auth()
        await this.get_basedata()
        await this.get_proposals().then((data) => {
          // console.log("return from get_proposal", data)
          this.init_done()
        })
      }
    },
  },
  async created() {
    this.init_start()
    await this.check_auth()
    await this.get_version()

    EventBus.$on('open-turnover-figure-window', (data) => {
      // this.proposal = this.proposals.find((e) => e.id == data.proposal_id)

      this.selected_proposal = this.proposals.find((e) => e.id == data.proposal_id)
      this.selected_proposal_id = data.proposal_id
      this.selected_turnover_figure_proposal_id = data.turnover_figure_proposal_id

      this.window_open = this.display_turnover_figure_window = true
    })

    EventBus.$on('close-turnover-figure-window', () => {
      this.window_open = this.display_turnover_figure_window = false
    })

    EventBus.$on('close-bill-upload-window', () => {
      this.window_open = this.display_bill_upload_window = false
    })

    EventBus.$on('close-impressum-window', () => {
      this.window_open = this.display_impressum_window = false
    })

    EventBus.$on('open-share-window', (proposal_id) => {
      console.log('open share window')
      this.selected_proposal_id = proposal_id
      this.window_open = this.display_share_window = true
    })

    EventBus.$on('close-share-window', () => {
      this.window_open = this.display_share_window = false
    })

    EventBus.$on('open-bill-upload-window', (data) => {
      console.log('open upload')
      this.selected_proposal_id = data.proposal_id
      this.selected_payment_period_id = data.payment_period_id
      this.selected_year = data.year
      this.selected_month = data.month
      this.selected_modus = data.modus

      this.window_open = this.display_bill_upload_window = true
    })

    EventBus.$on("open-ask-new-proposal-window", () => {
      if (this.proposals?.length >= 1) {
        this.proposals_count = this.proposals.length
        this.window_open = this.display_ask_new_proposal_window = true
      } else {
        this.new_proposal().then((response) => {
          this.proposals_count = 0
          this.proposal = this.proposals.find((e) => e.id == response.id)
          this.display_proposal = true
        })
      }
    })

    EventBus.$on("close-ask-new-proposal-window", () => {
      this.window_open = this.display_ask_new_proposal_window = false
    })

    EventBus.$on('open-edit', (id) => {
      this.proposal = this.proposals.find((e) => e.id == id)
      if (this.proposal) {
        this.display_proposal = true
      }
    })

    EventBus.$on('debug-switch', () => {
      this.display_debug = !this.display_debug
    })

    EventBus.$on('overview', (id) => {
      this.display_proposal = false
    })

    EventBus.$on('release-proposal', (id) => {
      // console.log("event release-proposal", id)
      this.release_proposal(id)
    })

    EventBus.$on('update-turnover-figure-proposal', (data) => {
      this.update_turnover_figure_proposal(data)
    })

    EventBus.$on('update_proposal', (data) => {
      let resp = this.update_proposal(data)
    })

    EventBus.$on('logout', () => {
      this.logout()
      this.clear_session()
      this.$store.state.login_state_email = false
      this.$store.state.login_state_token = false
    })
  },
  components: {
    Spinner,
    Impressum,
    AskNewProposalWindow,
    BillUploadWindow,
    Overview,
    Formular,
    Logo,
    Login,
    TurnoverFigureProposalWindow,
    ShareWindow,
    PageFooter
  },
}
</script>

<style>
.fade-enter,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}
</style>
