<template>
  <div>
    <h1 class="mt-4 font-semibold text-2xl text-center">
      Antrag auf Energiekostenzuschuss (EKZ)
    </h1>
    <div class="mt-8">
      <h3 class="font-bold">
        Anspruchvoraussetzungen <small>(Auszug aus den Richtlinien)</small>:
      </h3>
      <ul class="mt-2 pl-4 list-outside list-disc">
        <li>das Unternehmen hat seinen Hauptsitz in Liechtenstein und ist hier unbeschränkt steuerpflichtig;</li>
        <li>Das Unternehmen verfügt über eine liechtensteinische Gewerbebewilligung oder spezialgesetzliche Bewilligung, Anerkennung bzw. Konzession</li>
        <li>das Unternehmen ist vor dem vor dem 1. Januar 2022 gegründet und tätig geworden;</li>
        <li>der Jahresumsatz 2021 beträgt mindestens CHF 100‘000;</li>
        <li>Die inländischen Energiekosten des Unternehmens bezogen auf den Umsatz sind hoch. Die Ener- gieintensität wird mit dem Verhältnis der Energiekosten zum Umsatz im Geschäftsjahr 2021 ermittelt und das Verhältnis muss mindestens 3 Prozent betragen;</li>
        <li>Die Energie wird beim LKW und nicht mit einer eigenen Beschaffungsstrategie am freien Markt bezogen;</li>
        <li>Das Unternehmen ist von der Energiekostensteigerung unverhältnismässig stark betroffen. Bei einer Marge (Ergebnis vor Steuern im Verhältnis zum Umsatz) von kleiner 5 Prozent wird von einer starken Betroffenheit ausgegangen;</li>
        <li>das Unternehmen hat im Zeitpunkt der Antragsstellung keine überfälligen Steuer- und Sozialversicherungsschulden;</li>
        <li>keine rechtskräftige Abweisung eines Antrags auf Konkurseröffnung wegen fehlendem kosten- deckenden Vermögens oder Eröffnung eines rechtskräftig Konkurses in den letzten 3 Jahren;</li>
        <li>das Unternehmen verpflichtet sich, im Geschäftsjahr 2023 keine Dividenden und sonstigen Ge- winne auszuschütten sowie keine kurz- und langfristigen Aktivdarlehen zu gewähren und sich nicht an anderen Unternehmen zu beteiligen.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {}
</script>
