<template>
  <div>
    <title-and-teaser class="mt-10" />

    <div class="mb-24 mt-4 text-sm">
      <span class="font-bold text-2xl text-red-600">*</span>
      Alle Felder sind Pflichtfelder. Ungültige Inhalte werden mit rotem Rand
      dargestellt.
    </div>

    <pre v-if="debug" class="m-2 p-2 border">{{ proposal }}</pre>

    <!-- start company data -->
    <div class="mt-8" id="company-data">
      <h3 class="font-bold">1. Angaben zum Unternehmen:</h3>
      <div class="mt-2 flex justify-between items-center">
        <label class="w-1/3">Firma Bezeichnung</label>
        <input
          v-model.trim.lazy="proposal.company_name"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.company_name.$invalid }"
          type="text"
          placeholder="Firma Bezeichnung"
          v-focus
        >
      </div>
      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3">Adresse</label>
        <input
          v-model.trim.lazy="proposal.company_street"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.company_street.$invalid }"
          type="text"
          placeholder="Adresse"
        >
      </div>
      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3">Plz / Ort</label>
        <select
          v-model.lazy="proposal.company_city_id"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.company_city_id.$invalid }"
        >
          <option value="">---</option>
          <option v-for="city in cities" :key="city.id" :value="city.id">
            {{ city.zipcode }} / {{ city.name }}
          </option>
        </select>
      </div>
      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3">Telefon</label>
        <input
          v-model.trim.lazy="proposal.company_telefon"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.company_telefon.$invalid }"
          type="text"
          placeholder="Telefon"
        >
      </div>
      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3">Adresse der Betriebsstätte</label>
        <input
          v-model.trim.lazy="proposal.company_place_of_business"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{
            'border-red-600': v$.proposal.company_place_of_business.$invalid,
          }"
          type="text"
          placeholder="Adresse der Betriebsstätte"
        >
      </div>
      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3">PEID-Nr. des Unternehmens</label>
        <input
          v-model.trim.lazy="proposal.company_peid"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.company_peid.$invalid }"
          type="text"
          placeholder="PEID-Nr. des Unternehmens"
        >
      </div>

      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3">Branche</label>
        <select
          v-model.lazy="proposal.commercial_type_id"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.commercial_type_id.$invalid }"
        >
          <option value="">---</option>
          <option
            v-for="commercial_type in commercial_types"
            :key="commercial_type.id"
            :value="commercial_type.id"
          >
            {{ commercial_type.name }}
          </option>
        </select>
      </div>

      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3 leading-3">
          Lur Nr.
        </label>
        <input
          v-model.trim.lazy="proposal.lur_number"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.lur_number.$invalid }"
          type="text"
          placeholder="Lur Nummer">
      </div>

    </div>
    <!-- end company data -->

    <!-- start gov. classification -->
    <div id="gov-classification" class="mt-8">
      <h3 class="font-bold">2. Rückfragen</h3>

      <div class="mt-2 flex justify-left gap-2 items-baseline">
        <label
          for="proposal_disproportionately_affected"
          class="flex-1"
          :class="{
            'text-red-600': v$.proposal.disproportionately_affected.$invalid,
          }"
        >
          Ist das Unternehmen von der Energiekostensteigerung unverhältnismässig stark betroffen?
          (Die Gewinnmarge im Geschäftsjahr 2021, Ergebnis vor Steuer im Verhältnis zum Umsatz ist &lt; 5%).
        </label>
        <yes-no v-model="proposal.disproportionately_affected" :readonly="readonly" />
      </div>

      <!-- -->
      <div class="mt-2 flex justify-left gap-2 items-baseline">
        <label
          class="flex-1"
          :class="{
            'text-red-600': v$.proposal.foreign_turnover.$invalid,
          }"
        >
          Erzielen Sie Umsätze, die ausländischen Betriebsstätten zuzuordnen sind?
        </label>
        <yes-no v-model="proposal.foreign_turnover" :readonly="readonly" />
      </div>

      <!-- -->
      <div class="mt-2 flex justify-left gap-2 items-baseline">
        <label
          class="flex-1"
          :class="{
            'text-red-600': v$.proposal.open_tax_or_insurance.$invalid,
          }"
        >
          Hat das Unternehmen zum Zeitpunkt der Antragstellung überfällige
          Steuer- oder Sozialversicherungsschulden?
        </label>
        <yes-no v-model="proposal.open_tax_or_insurance" :readonly="readonly" />
      </div>

      <!-- -->
      <div class="mt-2 flex justify-left gap-2 items-baseline">
        <label
          class="flex-1"
          :class="{
            'text-red-600': v$.proposal.bankruptcy.$invalid,
          }"
        >
          Wurde in den letzten 3 Jahren vor Antragstellung über das
          Unternehmen rechtskräftig einen Konkursantrag oder Konkurs eröffnet?
        </label>
        <yes-no v-model="proposal.bankruptcy" :readonly="readonly" />
      </div>

      <!-- -->
      <div class="mt-2 flex justify-left gap-2 items-baseline">
        <label
          class="flex-1"
          :class="{
            'text-red-600': v$.proposal.no_divided_payment.$invalid,
          }"
        >
          Das Unternehmen bestätigt, im Geschäftsjahr 2023 keine
          Dividenden und sonstige Gewinne auszuschütten, keine kurz- und
          langfristigen Aktivdarlehen zu gewähren und sich nicht an anderen
          Unternehmen zu beteiligen.
        </label>
        <yes-no v-model="proposal.no_divided_payment" :readonly="readonly"  />
      </div>

      <!-- -->
      <div class="mt-2 flex justify-left gap-2 items-baseline">
        <label
          class="flex-1"
          :class="{
            'text-red-600': v$.proposal.own_procurement_strategy.$invalid
          }"
        >
          Wird die Energie mit einer eigenen Beschaffungsstrategie am freien Markt bezogen?
        </label>
        <yes-no v-model="proposal.own_procurement_strategy" :readonly="readonly" />
      </div>

      <!-- -->
      <div
        v-if="proposal?.own_procurement_strategy == 1"
        class="mt-2 flex justify-left gap-2 items-baseline"
      >
        <label
          class="flex-1"
          :class="{
            'text-red-600': v$.proposal.own_procurement_strategy_provider.$invalid
          }"
        >
          Der Energielieferant ist das LKW?
        </label>
        <yes-no v-model="proposal.own_procurement_strategy_provider" :readonly="readonly" />
      </div>

    </div>

    <!-- Trunover Figure Proposals -->
    <template v-if="proposal.turnover_figure_proposals">
      <h3 class="mt-8 my-4 text-md font-semibold">3a. Angaben zum Energieverbrauch</h3>

      <turnover-figure-proposal
        :proposal="proposal"
        :turnover_figure_proposal="only_last_active_turnover_figure_proposal"
        :readonly="readonly"
      />

      <div v-if="false">
        {{ v$["proposal"]["turnover_figure_proposals"] }}
      </div>
    </template>

    <div class="mt-8">
      <p class="mt-2">
        <strong>Hinweis zu den erforderlichen Stromrechnungen:</strong> Die Strom-Rechnung
        des LKW's laden Sie pro Monat oder Quartal auf Ihrer Übersichtsseite hoch.
        Die Daten werden nach Genehmigung des Antrags durch das AVW gesondert geprüft.
      </p>
    </div>
    <!-- Upload -->

    <!-- konto -->
    <div class="mt-8">
      <h3 class="font-bold">4. Kontoangaben</h3>
      <div class="mt-2 flex justify-between items-center">
        <label class="w-1/3" for>Kontoinhaber</label>
        <input
          v-model.trim.lazy="proposal.bank_account_name"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{
            'border-red-600': v$.proposal.bank_account_name.$invalid,
          }"
          type="text"
          placeholder="Kontoinhaber"
        >
      </div>
      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3" for>IBAN</label>
        <input
          v-model.trim="proposal.bank_iban"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500 outline-none"
          :class="{ 'border-red-600': v$.proposal.bank_iban.$invalid }"
          type="text"
          placeholder="IBAN"
        >
      </div>
      <div class="mt-1 flex justify-between items-center">
        <label class="w-1/3" for>(Post-)Bankverbindung</label>
        <input
          v-model.trim.lazy="proposal.bank_name"
          :disabled="readonly"
          class="w-2/3 px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.bank_name.$invalid }"
          type="text"
          placeholder="(Post-)Bankverbindung"
        >
      </div>
    </div>
    <!-- konto -->

    <div class="mt-8">
      <h3 class="font-bold">5. Verfahren</h3>
      <p class="mt-2">
        Dieser Antrag auf Energiekostenzuschuss für das Jahr 2023 ist einmalig einzureichen
        und dient zur Prüfung der Anspruchsberechtigung. In den nachfolgenden Quartalen
        reicht die Einreichung der Stromrechnung mit Nachweis und Deklaration der bezogenen
        Strommenge aus. Die LKW-Rechnungen sowie die Deklaration der bezogenen Strommengen
        können Sie pro Monat oder Quartal auf Ihrer Übersichtsseite hochladen und erfassen.
        Beachten Sie bitte, dass der Antrag auf EKZ für Q1/2023 bis spätestens 31. Mai 2023
        eingereicht sein muss. Die Einreichefristen für die folgende Quartale entnehmen Sie
        bitte der EKZ Richtlinie.
      </p>
    </div>

    <!-- Korrespondenz -->
    <div class="mt-8">
      <h3 class="font-bold">6. Korrespondenz</h3>
      <div class="mt-2 flex gap-2 items-baseline">
        <label for="proposal_correspondence_type_email" class="flex-1">
          Hiermit bestätige ich, dass alle weitere Korrespondenz ausschliesslich
          über die folgende E-Mail Adresse geführt wird:
        </label>
        <input
          v-model.trim.lazy="proposal.correspondence_email"
          :disabled="readonly"
          class="w-2/5 px-2 py-1 border border-gray-500"
          :class="{
            'border-red-600': v$.proposal.correspondence_email.$invalid,
          }"
          type="email"
          placeholder="Email Adresse"
        >
      </div>
    </div>
    <!-- Korrespondenz -->

    <!-- Verpflichtung -->
    <div class="mt-8">
      <h3
        class="font-bold"
        :class="{
          'text-red-600': !v$.proposal.given_infos_correct.$model,
        }"
      >
        7. Verpflichtung
      </h3>
      <div class="mt-2 flex gap-2 justify-left items-baseline">
        <checkbox
          v-model="proposal.given_infos_correct"
          id="proposal_given_infos_correct"
          :disabled="readonly" />
        <label for="proposal_given_infos_correct" class="flex-1">
          Hiermit bestätige ich, dass ich alle Angaben wahrheitsgetreu und
          vollständig eingetragen habe; auch nehme ich davon Kenntnis, dass ich
          mich für unwahre Angaben und das Verschweigen von Tatsachen, die zu
          einer ungerechtfertigten Auszahlung von Unterstützungsleistungen
          führen könnten, allenfalls strafbar mache und ich die zu Unrecht
          bezogenen Leistungen zurückzuerstatten habe.
        </label>
      </div>
    </div>
    <!-- Verpflichtung -->

    <!-- Vollmacht -->
    <div class="mt-8">
      <h3
        class="font-bold"
        :class="{
          'text-red-600': !v$.proposal.given_infos_further_use.$model ||
                            !v$.proposal.tax_to_notify_avw.$model
        }"
      >
        8. Vollmacht
      </h3>
      <div class="mt-2 flex gap-2 justify-left items-baseline">
        <checkbox
          v-model="proposal.given_infos_further_use"
          id="proposal_given_infos_further_use"
          :disabled="readonly" />
        <label for="proposal_given_infos_further_use" class="flex-1">
          Hiermit erteile ich dem Amt für Volkswirtschaft meine Einwilligung,
          die zur Prüfung dieses Antrags erforderlichen Auskünfte und Daten
          bei den zuständigen Behörden und Amtsstellen (z.B. Steuerverwaltung,
          Amt für Statistik, Amt für Justiz, AHV-IV-FAK Anstalten,
          Liechtensteinische Kraftwerke und andere Ämter, die spezialgesetzliche
          Berufszulassungen erteilen) einzuholen.
        </label>
      </div>

      <div class="mt-2 flex gap-2 justify-left items-baseline">
        <checkbox
          v-model="proposal.tax_to_notify_avw"
          id="proposal_tax_to_notify_avw"
          :disabled="readonly" />
        <label for="proposal_tax_to_notify_avw" class="flex-1">
          Ich ermächtige die Steuerverwaltung dem Amt für Volkswirtschaft allfällige
          Nichterfüllung der Anspruchsvoraussetzungen und allfällige Abweichungen bei
          der Umsatz-/Ertragsdeklaration mitzuteilen, wenn sie solche im Rahmen der
          Überprüfung des Antrags oder in einem späteren Zeitpunkt (z.B. Prüfung der
          Deklaration, Nachdeklaration, Prüfung der Jahresrechnung etc.) feststellen
          sollte.
        </label>
      </div>

    </div>
    <!-- Vollmacht -->

    <!-- signatur -->
    <div class="mt-8 flex gap-2 justify-between">
      <div class="mt-2 w-1/2 flex gap-2 justify-left items-center">
        <input
          v-model.trim.lazy="proposal.signature_city"
          :disabled="readonly"
          class="w-full px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.signature_city.$invalid }"
          type="text"
          placeholder="Ortsangabe zum Antragsteller"
        >
      </div>
      <div class="ml-1 mt-2 w-1/2 flex gap-2 justify-left items-center">
        <input
          v-model.lazy="proposal.signature_date"
          :disabled="readonly"
          class="ml-1 w-full px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.proposal.signature_date.$invalid }"
          type="date"
          placeholder="Datum der Antragstellung (TT.MM.YYYY)"
        >
      </div>
    </div>
    <!-- signatur -->

    <!-- signatur -->
    <div class="mt-1 flex gap-2 justify-between">
      <div class="mt-2 w-1/2 items-center">
        <input
          v-model.trim.lazy="proposal.signature_company"
          :disabled="readonly"
          class="w-full px-2 py-1 border border-gray-500"
          :class="{
            'border-red-600': v$.proposal.signature_company.$invalid,
          }"
          type="text"
          placeholder="Name des Zeichnungsberechtigten"
        />
        <label class="text-sm w-full">Zeichnungsberechtige/r des Unternehmens</label>
      </div>
      <div class="mt-2 ml-2 w-1/2">
        <input
          v-model.trim.lazy="proposal.signature_company_personal"
          :disabled="readonly"
          class="w-full px-2 py-1 border border-gray-500"
          type="text"
          placeholder="Name des Zeichnungsberechtigten"
        >
      </div>
    </div>
    <!-- signatur -->

    <!-- save and process -->
    <div class="mt-8 flex gap-2 justify-between">
      <button
        @click="overview"
        class="px-4 py-2 bg-gray-600 hover:bg-gray-800 text-white rounded-sm shadow"
      >
        <font-awesome-icon class="mr-1" icon="th-list" />Übersicht
      </button>
      <!-- -->
      <div v-if="!readonly" class="flex gap-1">
        <button
          @click="true"
          :disabled="readonly"
          class="px-4 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded-sm shadow"
        >
          <font-awesome-icon class="mr-1" icon="save" />Daten speichern
        </button>
        <!-- -->
        <button
          @click="release"
          :disabled="readonly || !all_data_valid"
          class="px-4 py-2 text-white rounded-sm shadow"
          :class="{
            'bg-gray-300 cursor-default': !all_data_valid,
            'bg-green-500': all_data_valid,
          }"
        >
          Daten sind korrekt, Antrag einreichen
          <font-awesome-icon class="ml-1" icon="chevron-right" />
        </button>
      </div>
    </div>

    <div v-if="errors" class="fixed top-0 left-0 flex h-screen w-screen">
      <div class="login-window w-1/2 bg-white p-10 m-auto">
        <h1 class="flex justify-between text-lg font-semibold mb-3">
          Fehlermeldung
          <span class="cursor-pointer" @click="clear_errors">
            <font-awesome-icon icon="times" />
          </span>
        </h1>

        <div v-for="(line, idx) in display_errors()" :key="idx">{{ line }}</div>
      </div>
    </div>

    <div v-if="success" class="fixed top-0 left-0 flex h-screen w-screen">
      <div class="login-window w-1/2 bg-white p-5 m-auto relative">
        <h1 class="flex justify-between text-lg font-semibold mb-3">
          Daten gespeichert ...
          <span
            @click="call_clear_success"
            class="px-2 py-1 cursor-pointer absolute right-0 top-0 text-2xl hover:bg-gray-800 hover:text-white"
          >
            <font-awesome-icon icon="times" />
          </span>
        </h1>

        <p>
          Ihre Daten wurden erfolgreich an das AVW übermittelt. Bitte beachten
          Sie auch den Status in der Übersicht.
        </p>
      </div>
    </div>

    <pre v-if="debug" class="m-2 p-2 border">{{ v$ }}</pre>

    <!-- save and process -->
    <formular-footer />

  </div>
</template>

<script>
import EventBus from '../utils/event-bus'
import Api from '../utils/api'
// import diff from '../utils/diff'

import { useVuelidate } from '@vuelidate/core'
import {
  required,
  requiredIf,
  email,
  numeric,
  minLength
} from '@vuelidate/validators'

import isIBANValid from '../utils/iban.js'

// const validateIf = (prop, validator) =>
//   helpers.withParams({ type: 'validatedIf', prop }, function (value, parentVm) {
//     return helpers.ref(prop, this, parentVm) ? validator(value) : true
//   })

function validateIf(condition, rule) {
  return condition ? rule : {}
}

import TurnoverFigureProposal from '../components/turnover-figure-proposal.vue'
import TitleAndTeaser from '../components/title-and-teaser.vue'
import FormularFooter from '../components/footer.vue'
import Checkbox from '../components/checkbox.vue'
import YesNo from '../components/yes_no.vue'

import { mapMutations, mapGetters } from 'vuex'

const iban = (value) => {
  return isIBANValid(value)
}

const phone = (value) => {
  return /^[0-9 +]+$/.test(value)
}

export default {
  props: {
    proposal: {
      type: Object,
      required: true
    },
    debug: {
      type: [Number, String, Boolean],
      default: false
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      old_proposal: null,
      tmp_commercial_type_short: '',
      csrf: null,
      session_id: null,
      progress: 0
    }
  },
  mounted() {
    this.old_proposal = this.proposal
    this.csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    this.session_id = document.getElementsByTagName('body')[0]?.dataset.id

    this.clear_success()
    this.clear_errors()
  },
  methods: {
    ...mapMutations(['clear_errors', 'clear_success', 'set_success']),
    ...mapGetters(['display_errors']),
    call_clear_success() {
      this.clear_success
      EventBus.$emit('overview')
    },
    release() {
      if (!this.readonly && !this.v$.$invalid) {
        EventBus.$emit('release-proposal', this.proposal.id)
      }
    },
    overview() {
      EventBus.$emit('overview')
    },
    logout() {
      EventBus.$emit('logout')
    },
  },
  computed: {
    ...mapGetters(['cities', 'commercial_types', 'errors', 'success']),
    only_last_active_turnover_figure_proposal() {
      const ret = this.proposal.turnover_figure_proposals.find((e) => {
        if (
          e.status == -1 ||
          e.status == 0 ||
          e.status == 20 ||
          e.status == 99 ||
          e.status == 100
        )
          return true
      })
      return ret
    },
    readonly() {
      return this.proposal.status != -1
    },
    computed_proposal: function () {
      return Object.assign({}, this.proposal)
      // return this.proposal
    },
    all_data_valid() {
      console.log('all_data_valid: ', !this.v$.$invalid)
      console.log('turnover: ', this.only_last_active_turnover_figure_proposal)
      return (
        !this.v$.$invalid &&
        this.only_last_active_turnover_figure_proposal?.documents?.length > 0
      )
    }
  },
  watch: {
    computed_proposal: {
      handler(n, o) {

        if (this.readonly) return o

        // make map and compare old with new values
        // only send changed values

        const changed_data = new Map(Object.entries(n))
        changed_data.forEach((v, k, m) => {
          if (changed_data.get(k) === o[k]) m.delete(k)
        })

        // console.log(this.old_proposal)
        // let changed_data = diff(o, n)
        // console.log("Formular:watch:coomputed_proposal", changed_data.size)

        if (changed_data && changed_data.size > 0) {
          EventBus.$emit('update_proposal', {
            id: n.id,
            data: Object.fromEntries(changed_data)
          })
        }
      },
      deep: true
    }
  },
  validations: {
    proposal: {
      company_name: { required, minLength: minLength(3) },
      company_street: { required, minLength: minLength(5) },
      company_city_id: { required },
      company_peid: { required, numeric, minLength: minLength(4) },
      company_telefon: { phone, required, minLength: minLength(6) },
      company_place_of_business: { required, minLength: minLength(5) },
      commercial_type_id: { required },
      lur_number: { numeric },
      // lur_number: {
      //   // numeric,
      //   // minLength: minLength(4),
      //   minLength: validateIf("'tmp_commercial_type_short'", minLength(4)),
      //   required: requiredIf(function () {
      //     this.tmp_commercial_type_short = false
      //     if (!this.proposal.commercial_type_id) return false
      //     // console.log(this.commercial_types)
//
      //     let ct = this.commercial_types.find(
      //       (c) => c.id === this.proposal.commercial_type_id
      //     )
      //     // console.log('ct', ct.shortname == 'i')
      //     this.tmp_commercial_type_short = ct.shortname == 'i'
      //     return ct.shortname == 'i'
      //   })
      // },

      disproportionately_affected: { required },
      open_tax_or_insurance: { required },
      bankruptcy: { required },
      no_divided_payment: { required },
      foreign_turnover: { required },
      own_procurement_strategy: { required },
      own_procurement_strategy_provider: {
        required: requiredIf(function () {
          return this.proposal.own_procurement_strategy > 0
        })
      },

      correspondence_email: { email, required },
      bank_account_name: { required, minLength: minLength(5) },
      bank_iban: { iban, required },
      bank_name: { required, minLength: minLength(3) },
      given_infos_correct: {
        required() {
          return this.proposal.given_infos_correct > 0
        }
      },
      given_infos_further_use: {
        required() {
          return this.proposal.given_infos_further_use > 0
        }
      },
      tax_to_notify_avw: {
        required() {
          return this.proposal.tax_to_notify_avw > 0
        }
      },
      signature_city: { required, minLength: minLength(3) },
      signature_date: { required },
      signature_company: { required, minLength: minLength(3) }
    }
  },
  components: {
    TitleAndTeaser,
    FormularFooter,
    TurnoverFigureProposal,
    YesNo,
    Checkbox
  }
}
</script>

<style scoped>
select {
  height: 2.2em;
}
input[readonly],
button[readonly],
button:disabled {
  background-color: lightgray;
}
</style>
