<template>
  <div id="page-footer">
    <div class="flex gap-2 p-2 text-sm bg-white w-auto">
      <span>© 2023 Amt für Volkswirtschaft</span>

      <a v-if="logged_in" @click="$emit('open-impressum')" class="cursor-pointer"> Impressum</a>
      <a v-else href="https://avw.li/impressum.html" target="_blank"> Impressum</a>

      <a href="https://www.llv.li/de/navigation/footer-nav/rechtshinweise" target="_blank">Rechtshinweise</a>
      <a href="https://www.llv.li/de/landesverwaltung/amt-fuer-volkswirtschaft/datenschutzhinweis" target="_blank">Datenschutzerklärung</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logged_in: {
      type: [Boolean],
      default: false
    }
  }
}
</script>

<style scoped>
#page-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
#page-footer a::before {
  content: '› '
}
</style>