<template>
  <input
    type="checkbox"
    :checked="modelValue == yesValue"
    @change="updateValues($event.target.checked)"
    class="form-checkbox"
  >
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  readonly: {
    type: [Number, Boolean],
    default: false
  },
  modelValue: {
    type: [Number, String],
    default: null
  },
  yesValue: {
    type: [Number, String],
    default: 1
  },
  noValue: {
    type: [Number, String],
    default: 0
  }
})

function updateValues(value) {
  if(props.readonly) return
  emit('update:modelValue', value)
}
</script>
