<template>
  <div
    @click.self="close_window"
    class="fixed w-full h-full inset-0 flex z-30"
  >
    <div class="relative overflow-auto rounded m-auto shadow-xl p-5 max-w-xl max-h-[75vh] bg-white">
      <h1 class="font-semibold text-lg flex justify-between">
        <span>Stromrechnung hochladen</span>
        <span
          @click.stop="close_window"
          class="px-2 py-1 text-xl absolute cursor-pointer top-2 right-0 -mt-2 text-gray-800 hover:bg-gray-800 hover:text-white"
        >
          <font-awesome-icon icon="times" />
        </span>
      </h1>

      <pre v-if="false">
        {{ current_proposal.bills }}
        {{ bill }}
      </pre>

      <p class="mt-5 text-sm">
        Sie laden nachfolgende eine Monatsrechnung oder Quartalsrechnung für den Zeitraum
        {{ year }} / {{ month }} zur Überprüfung durch das AVW hoch.
      </p>

      <div class="mt-4 grid grid-cols-4 gap-2 justify-between items-center">
        <label class="col-span-3 text-sm">
          Strombezug Wirkenergie Hochtarif (HT) [KWh]<sup>2</sup>
        </label>
        <input
          v-model.trim.lazy="bill.energy_usage_high_rate"
          @change="store_changes"
          :disabled="readonly"
          class="px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.bill.energy_usage_high_rate.$invalid }"
          type="text"
        >
      </div>

      <div class="mt-1 grid grid-cols-4 gap-2 justify-between items-center">
        <label class="col-span-3 text-sm">
          Strombezug Wirkenergie Niedertarif (NT) [KWh]<sup>2</sup>
        </label>
        <input
          v-model.trim.lazy="bill.energy_usage_low_rate"
          @change="store_changes"
          :disabled="readonly"
          class="px-2 py-1 border border-gray-500"
          :class="{ 'border-red-600': v$.bill.energy_usage_low_rate.$invalid }"
          type="text"
        >
      </div>

      <file-upload
        v-if="loaded"
        ref="bill_files"
        v-model="bill_files"
        :drop="!readonly"
        :drop-directory="false"
        :multiple="false"
        :maximum="1"
        class="w-full"
        :disabled="readonly"
        @input-filter="input_filter"
        @input-file="input_file"
      >
        <div
          class="p-3 mt-4 flex flex-wrap gap-4 items-center place-items-center border border-dashed border-gray-600 rounded w-full"
          :class="{
            'bg-green-300': $refs.bill_files && $refs.bill_files.dropActive
          }"
          style="min-height: 120px;"
        >
          <div
            v-if="bill?.documents?.length <= 0"
            class="text-center w-full text-sm text-gray-500"
          >
            Klicken Sie in dieses Feld um Dateien hochzuladen<br>
            oder ziehen Sie vom Windows Explorer oder MacOS Finder PDF
            Dokumente über diesen Bereich<br>
            und lassen Sie diese dann fallen um Dateien hochzuladen.
          </div>
          <div v-for="file in bill.documents" :key="file.id" class="flex gap-4 z-10">
            <div
              class="relative p-2 border flex flex-col justify-between w-40 h-32 items-center hover:bg-green-100 hover:cursor-pointer"
            >
              <div
                v-if="!readonly"
                class="bg-gray-700 text-white absolute top-0 right-0 px-2 py-1 opacity-20 hover:opacity-100">

                <button @click="remove_download(file.id)" class="hover:text-red-500">
                  <font-awesome-icon icon="trash" />
                </button>
              </div>

              <a :href="file.filepath" target="_blank" class="z-20">
                <img src="../images/pdf.png" class="w-12 mt-2">
              </a>
              <span class="text-xs break-all">{{ file.name || file.filename }}</span>

              <div v-if="progress > 0" class="w-48">
                <div id="upload-progress-bar" class="h-3 rounded bg-green-400 w-0" :style="{ width: progress + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
      </file-upload>
      <p class="text-sm text-center">
        Bitte beachten Sie, dass nur ein Dokument hochgeladen werden kann.
      </p>

      <div class="mt-5 flex justify-between items-center">
        <button
          @click="bill_delete"
          class="ml-1 text-white px-3 py-2 shadow-sm bg-red-500 rounded disabled:bg-red-200"
          :disabled="readonly"
        >
          <font-awesome-icon class="mr-1" icon="trash" />
          Daten löschen
        </button>

        <button
          @click="bill_release"
          class="ml-1 text-white px-3 py-2 shadow-sm bg-green-500 rounded disabled:bg-green-200"
          :disabled="readonly || ( !bill?.documents || bill?.documents?.length <= 0 ) || v$.$invalid"
        >
          <font-awesome-icon class="mr-1" icon="save" />
          Daten freigeben
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../utils/event-bus.js'
import { mapActions, mapState } from 'vuex'
import Api from '../utils/api'
import FileUpload from 'vue-upload-component'
import { DirectUpload } from '@rails/activestorage'

import { useVuelidate } from '@vuelidate/core'
import { required, numeric } from '@vuelidate/validators'

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  props: {
    proposal_id: {
      type: [String, Number],
      required: true
    },
    payment_period_id: {
      type: [String, Number],
      required: true
    },
    year: {
      type: [String, Number],
      required: true
    },
    month: {
      type: [String, Number],
      required: true
    },
    modus: {
      type: [Boolean],
      default: false
    }
  },
  data() {
    return {
      session_id: null,
      csrf: null,
      loop: false,
      bill: {},
      bill_files: [],
      readonly: true,
      loaded: false
    }
  },
  methods: {
    ...mapActions(['new_proposal', 'update_bill', 'release_bill', 'delete_bill']),
    bill_release() {
      this.release_bill({ proposal_id: this.bill.proposal_id, id: this.bill.id })
          .then((response) => {
            // this.bill.status = response.data.status
            this.close_window()
          })
    },
    bill_delete() {
      this.delete_bill({ proposal_id: this.bill.proposal_id, id: this.bill.id })
          .then(() => this.close_window())
    },
    close_window() {
      EventBus.$emit('close-bill-upload-window')
    },
    directUploadWillStoreFileWithXHR(request) {
      console.log('>>> uploading', request)
      request.upload.addEventListener('progress', (evt) =>
        this.directUploadDidProgress(evt)
      )
    },
    directUploadDidProgress(event) {
      console.log('>>> uploading progress', event)
      this.progress = Math.floor((event.loaded / event.total) * 100)
    },
    async remove_download(file_id) {
      console.log('remove file', file_id)
      await Api().get(`uploads/remove/${file_id}`)
                 .then((r) => {
                   this.bill.documents.splice(
                     this.bill.documents.find((e) => e.id == file_id),
                     1
                   )
                 })
                 .catch((e) => console.log('error removing file', e))
    },
    input_filter(new_file, old_file, prevent) {
      console.log('input_filter', new_file, old_file)
      if (!/\.(pdf|jpeg|jpe|jpg|gif|png|webp)$/i.test(new_file.name)) {
        return prevent()
      }
      console.log('input_filter', this?.bill?.documents?.length)
      if (this?.bill?.documents?.length >= 1) prevent()
    },
    input_file(new_file, old_file) {
      console.log('input_file, new_file:', new_file, 'old_file:', old_file)

      if (!new_file) return

      if (!this.bill?.documents) this.bill.documents = []
      this.progress = 1
      const url = `api/v1/uploads`

      const upload = new DirectUpload(
        new_file?.file,
        `${url}?session_id=${this.session_id}&bill_id=${this.bill.id}`,
        this
      )

      upload.create((error, blob) => {
        if (error) {
          console.log('got an error')
        } else {
          this.progress = 0
          console.log('successfully', blob)
          if (blob.virus_found) {
            alert("Wir haben die hochgeladenen Daten überprüft und eine Auffälligkeit festgestellt.\nEs wurde eine Virensignatur gefunden.\nBitte überprüfen Sie die Daten selbst." )
          } else {
            this.blob = blob
            this.bill.documents.push(blob)
          }
        }
      })
    },
    store_changes() {
      console.log('bill', this.bill)
      this.update_bill({
        id: this.bill.id,
        proposal_id: this.bill.proposal_id,
        payment_period_id: this.bill.payment_period_id,
        data: this.bill
      }).then(e => this.bill = e.data)
        .catch(e => console.log('c',e))
    }
  },
  computed: {
    ...mapState(['proposals']),
    computed_bill() {
      return Object.assign({}, this.bill)
    },
    current_proposal() {
      return this.proposals.find((p) => p.id == this.proposal_id)
    }
  },
  validations: {
    bill: {
      proposal_id: { required },
      payment_period_id: { required },
      month: { required, numeric },
      energy_usage_high_rate: { required, numeric },
      energy_usage_low_rate: { required, numeric }
    }
  },
  mounted() {
    // console.log('----- mounted current_proposals', this.current_proposal.bills, this.proposal_id, this.payment_period_id, this.month)
    this.bill = this.current_proposal.bills.find((b) => {
      return b.payment_period_id == this.payment_period_id &&
             parseInt(b.month) == parseInt(this.month) &&
             this.modus == false
    })
    if (!this.bill) {
      this.bill = {
        id: null,
        proposal_id: this.proposal_id,
        payment_period_id: this.payment_period_id,
        month: this.month,
        energy_usage_high_rate: null,
        energy_usage_low_rate: null,
        status: -1,
        documents: []
      }
      this.update_bill({
        id: this.bill.id,
        proposal_id: this.bill.proposal_id,
        payment_period_id: this.bill.payment_period_id,
        data: this.bill
      }).then((e) => {
        this.bill = e.data
      }).catch(e => console.log('c', e))
    }

    this.readonly = this.bill.status >= 0

    this.csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    this.session_id = document.getElementsByTagName('body')[0].dataset.id

    console.log('mounted', this.proposal_id)
    console.log('mounted', this.proposals)
    console.log('mounted', this.proposals.find( (p) => p.id == this.proposal_id))

    this.loaded = true
  },
  unmounted() {
    this.loaded = false
  },
  components: {
    FileUpload
  }
}
</script>
