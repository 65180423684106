<template>
  <nav class="flex flex-row justify-between px-1 pt-1">
    <div>
      <img
        @click="overview"
        src="../images/logo.png"
        class="h-32 cursor-pointer"
        alt="AVW - Amt für Volkswirtschaft - Liechtenstein"
      >
    </div>
    <div class="text-gray-500 text-end">
      {{ version.title }}
      <span class="version block text-gray-100">{{ version.version }}</span>
    </div>
  </nav>
</template>

<script>
import EventBus from '../utils/event-bus'
import logo from '../images/logo.jpg'

export default {
  props: {
    version: {
      type: Object,
      required: true
    }
  },
  methods: {
    overview() {
      EventBus.$emit('overview')
    },
  }
}
</script>

<style scoped>
br { height: 0; }
.version { font-size: 9px; }
</style>