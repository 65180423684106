<template>
  <div class="mb-24 mt-10 md:mr-8 text-sm">
    <h2 class="mb-1 font-semibold">Erläuterung der Farben</h2>
    <div class="mt-2 md:flex">
      <div class="mr-4">
        <div class="flex gap-2 mb-1 items-start">
          <span
            class="flex h-5 w-5 justify-center items-center text-xs font-semibold bg-blue-300 mr-1"
            >-</span
          >
          Antrag/Umsatzzahlen in Arbeit durch Antragsteller
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <span
            class="flex h-5 w-5 justify-center items-center text-xs font-semibold bg-yellow-500 mr-1"
            >B</span
          >
          Antrag wird durch AVW bearbeitet
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <span
            class="flex h-5 w-5 justify-center items-center text-xs text-white font-semibold bg-orange-500 mr-1"
            >K</span
          >
          Kontrolle durch AVW
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <span
            class="flex h-5 w-5 justify-center items-center text-xs text-white font-semibold bg-indigo-600 mr-1"
            >F</span
          >
          Kontrolle durch AVW/Steuerverwaltung
        </div>
      </div>
      <div>
        <div class="flex gap-2 mb-1 items-start">
          <span
            class="flex h-5 w-5 justify-center items-center text-xs text-white font-semibold bg-green-700 mr-1"
            >O</span
          >
          Antrag wurde genehmigt
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <span
            class="flex h-5 w-5 justify-center items-center text-xs text-white font-semibold bg-red-700 mr-1"
            >X</span
          >
          Antrag wurde abgewiesen
        </div>
        <div class="flex gap-2 mb-1 items-start">
          <span
            class="flex h-5 w-5 justify-center items-center text-xs text-white font-semibold bg-red-700 mr-1"
            >S</span
          >
          Antrag wurde storniert
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>
