const ProposalStatus = {
  STATUS_IS_NOT_RELEASED: -1,
  STATUS_IS_OPEN: 0,
  STATUS_IS_VALID: 20,
  STATUS_IS_REFUSED: 99,
  STATUS_IS_ACCEPTED: 100,
  STATUS_IS_VIOLATION: 190,
  STATUS_IS_STORNO: 199
}

export default ProposalStatus
