<template>
  <div class="mt-4 p-4 bg-yellow-300 shadow rounded">
    <h3 class="font-semibold">Bitte beachten Sie!</h3>
    <p class="mt-2">
      Sie erfassen einen Antrag und senden diesen an das AVW.
      Erst <strong>nach dem Absenden</strong> können Sie die erforderlichen Stromrechnungen
      an das AVW hochladen.
    </p>
    <p class="mt-2">
      Für die Abgabe der <strong>Stromrechnungen</strong> wählen Sie unter dem
      entsprechenden Antrag bitte den Button
      <button
        class="shadow rounded-sm w-6 px-1 py-0 bg-blue-500 text-xs text-white mr-1"
      >
        <font-awesome-icon icon="money-check" /></button
      >.
    </p>
    <p class="mt-1">
      Voraussetzung für eine abschliessende Prüfung Ihres Antrages sind die
      <strong>erforderlichen Dokumente (als PDF)</strong> die Sie im Antrag mit
      hochladen.
    </p>
  </div>
</template>
