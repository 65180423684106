<template>
  <div
    @click.self="close_window"
    class="fixed w-full h-full inset-0 flex z-30"
  >
    <div class="relative overflow-auto rounded m-auto border border-gray-200 shadow-xl p-5 max-w-xl max-h-[75vh] bg-white">
      <h1 class="font-semibold text-lg flex justify-between">
        <span>Berechtigungen</span>
        <span
          @click.stop="close_window"
          class="px-2 py-1 text-2xl absolute cursor-pointer top-2 right-0 -mt-2 text-gray-800 hover:bg-gray-800 hover:text-white"
        >
          <font-awesome-icon icon="times" />
        </span>
      </h1>

      <p class="mt-4">
        Sie wollen für den Antrag <strong class="font-medium">{{ proposal.company_name }} / {{ proposal.token }}</strong>
        Berechtigung erteilen oder die Hauptverantwortung übertragen.
      </p>

      <h2 class="mt-4 font-semibold">
        Berechtigungen
      </h2>

      <table class="w-full mt-4 border-collapse">
        <tr>
          <th class="pl-1 text-sm text-left font-normal">Email Adresse</th>
          <th class="pl-1 text-sm text-left font-normal">Berechtigung</th>
          <th></th>
        </tr>
        <tr>
          <td class="py-1 pr-2">
            <input
              v-model="email"
              type="email"
              class="w-full px-2 py-1.5 border border-gray-500"
              placeholder="Email Adresse"
              v-focus
              required
            >
          </td>
          <td class="py-1">
            <select
              v-model="mode"
              class="w-full px-2 py-2 border border-gray-500 bg-transparent"
              required
            >
              <option value="0"></option>
              <option value="1">Anzeige</option>
              <option value="2">Bearbeitung</option>
            </select>
          </td>
          <td class="py-1">
            <button
              @click="share_add"
              class="ml-2 text-white px-2 py-1 rounded hover:bg-green-600"
              :class="{
                'bg-green-400': input_valid,
                'bg-gray-400': !input_valid
              }"
              :disabled="!input_valid"
            >
              <font-awesome-icon icon="save" />
            </button>
          </td>
        </tr>

        <!-- Edit List -->
        <tr v-for="share in shares" :key="share.id">
          <td class="py-1 pl-1">{{ share.email }}</td>
          <td class="py-1 pl-1">
            <span
              class="px-2 py-1.5 rounded-xl text-white text-sm"
              :class="{
                'bg-blue-500': share.mode == 1,
                'bg-green-500': share.mode == 2,
             }">
              {{ display_mode(share.mode) }}
            </span>
          </td>
          <td class="py-1">
            <button
              @click="share_delete(share.id)"
              class="ml-2.5 text-sm text-white px-2 py-1 bg-red-500 rounded mr-1 ml-1"
            >
              <font-awesome-icon icon="trash" />
            </button>
          </td>
        </tr>
      </table>

      <div class="mb-4 mt-8 p-4 bg-red-100">
        <h2 class="font-semibold">
          Sie wollen die Hauptverantwortung dieses Antrags übertragen
        </h2>

        <div class="mt-3">
          <p>
            Ich bin mir bewusst, dass ich dem Inhaber der nachfolgenden Email Adresse
            die gesamten Bearbeitungsrechte dieses Antrags übertrage.
          </p>
          <div>
            <input
              type="text"
              placeholder="Email Adresse des neuen Verantwortlichen"
              class="mt-2 w-2/3 px-2 py-2 border border-gray-500 bg-white"
            >
            <button
              @click="bill_release"
              class="ml-1 text-white px-3 py-2 shadow-sm bg-red-600 rounded disabled:bg-green-200"
            >
              Übertragen <font-awesome-icon class="" icon="arrow-right" />
            </button>
          </div>

          <p class="mt-4 text-xs">
            Diese Vorgang wird erst ausgeführt, wenn sich die angeschriebene
            Person im AVW:EKZ registriert hat.
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import EventBus from '../utils/event-bus.js'
import { mapActions, mapState } from 'vuex'
import Api from '../utils/api'

export default {
  setup() {
    return {}
  },
  props: {
    proposal_id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      session_id: null,
      csrf: null,
      email: null,
      mode: null,
      shares: []
    }
  },
  methods: {
    ...mapActions(['get_shares', 'write_share', 'delete_share', 'transfer_ownership']),
    close_window() {
      EventBus.$emit('close-share-window')
    },
    display_mode(mode) {
      return [
        { id: 0, title: '--' },
        { id: 1, title: 'Anzeige' },
        { id: 2, title: 'Bearbeitung' }
      ].find((e) => e.id == mode)['title']
    },
    share_add() {
      this.write_share({
        proposal_id: this.proposal_id,
        email: this.email,
        mode: this.mode
      }).then((r) => {
        this.shares.push(r.data)
        this.mode = this.email = null
      })
      .catch((e) => console.log('got an error while writing share data'))
    },
    share_delete(id) {
      this.delete_share({
        proposal_id: this.proposal_id,
        id: id
      }).then((r) => {
        this.shares.splice(this.shares.indexOf(this.shares.find(e => e.id == r.id)), 1)
      }).catch(e => console.log('got an error while deleting a share', id))
    }
  },
  computed: {
    ...mapState(['proposals']),
    proposal() {
      return this.proposals.find((p) => p.id == this.proposal_id)
    },
    input_valid() {
      return this.email && this.mode
    }
  },
  mounted() {
    this.csrf = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')
    this.session_id = document.getElementsByTagName('body')[0].dataset.id

    this.get_shares(this.proposal_id)
        .then((r) => {
          console.log('*** got shares for proposal:', this.proposal.id, r)
          this.shares = r
        })
        .catch((e) => console.log('*** got shares error:', error) )
  }
}
</script>

<style scoped>
input[readonly],
button[readonly],
button:disabled {
  background-color: lightgray;
}
</style>
