<template>
  <div class="flex m-auto justify-center items-center">
    <div class="flex gap-2 mt-40 p-10 w-3/6 login-window">
      <font-awesome-icon
        class="mr-2"
        icon="spinner"
        size="lg"
        pulse
        title="Daten werden geladen ..."
      />Daten werden geladen ...
    </div>
  </div>
</template>
