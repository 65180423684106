<template>
  <div class="mt-8 mb-8 text-sm font-semibold">
    Bei elektronischer Einreichung wird bis auf weiteres auf die
    Unterschriftenerfordernis verzichtet. Die Unterzeichnung des Antrags kann
    ohne Angabe von Gründen jederzeit seitens des AVW angefordert werden.
  </div>
</template>

<script>
export default {}
</script>
