import '../ekz/stylesheets/main.scss'

// const images = require.context('../ekz/images', true)
// const imagePath = (name) => images(name, true)

// import autofocus from 'vue-autofocus-directive'
// import Vuelidate from 'vuelidate'
import VueNumeric from '@handcrafted-market/vue3-numeric'
// import VueMoment from 'vue-moment-v3'

import { store } from '../ekz/stores'
import App from '../ekz/app.vue'

import { createApp } from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faBug,
  faChevronRight,
  faClock,
  faDoorClosed,
  faDoorOpen,
  faDownload,
  faInfo,
  faEdit,
  faFileAlt,
  faMoneyCheck,
  faMoneyCheckAlt,
  faPlus,
  faSave,
  faSpinner,
  faThList,
  faTimes,
  faTrash,
  faShare,
  faArrowRightArrowLeft
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faMoneyCheck,
  faBug,
  faMoneyCheckAlt,
  faClock,
  faTimes,
  faSpinner,
  faDownload,
  faSave,
  faDoorOpen,
  faDoorClosed,
  faInfo,
  faEdit,
  faFileAlt,
  faTrash,
  faPlus,
  faThList,
  faChevronRight,
  faArrowRight,
  faShare,
  faArrowRightArrowLeft,
)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Vue.component('font-awesome-icon', FontAwesomeIcon)
// Vue.config.devtools = process.env.NODE_ENV == 'development'
// Vue.config.productionTip = process.env.NODE_ENV == 'development'

// disable debug log
console.log = function() {}

document.addEventListener('DOMContentLoaded', () => {
  const el = '#ekz'
  const ekz = document.querySelectorAll(el)
  if (ekz.length > 0) {
    console.log('hier')
    createApp(App)
      .use(store)
      .component('vue-numeric', VueNumeric)
      .component('font-awesome-icon', FontAwesomeIcon)
      .directive('focus', {
        mounted(el) {
          el.focus()
        }
      })
      .mount(el)
  }
})
