<template>
  <div>

    <div v-if="debug">
      {{ turnover_figure_proposal }}
    </div>

    <div v-if="debug">
      {{ v$.turnover_figure_proposal }}
    </div>

    <div class="mt-6 flex justify-left gap-2 items-center">
      <label
        class="flex-1 leading-tight"
        :class="{
          'text-red-600': v$.turnover_figure_proposal.turnover_minimum_reached.$invalid,
        }">Der im Inland erzielte Jahresumsatz im Jahr 2021 beträgt mindestens CHF 100'000
      </label>
      <yes-no v-model="turnover_figure_proposal.turnover_minimum_reached" :readonly="readonly" />
    </div>

    <!-- Zusatzfrage bei Umsatz kleiner als 100'000 -->
    <div
      v-if="turnover_figure_proposal.turnover_minimum_reached == 0"
      class="mt-3 flex gap-2 justify-end items-center">
      <label
        class="flex-1 leading-tight"
        :class="{
          'text-red-600': v$.turnover_figure_proposal.turnover_2021.$invalid,
        }">Bei unterjähriger Betriebsaufnahme im 2021 ‐ Umsatz im 2021 [CHF]
      </label>
      <input
        type="text"
        id="proposal_turnover_minimum_reached_yes"
        v-model="turnover_figure_proposal.turnover_2021"
        :disabled="readonly"
        class="w-3/12 px-2 py-1 border border-gray-500 text-right"
      >
    </div>

    <div
      v-if="turnover_figure_proposal.turnover_minimum_reached == 0"
      class="mt-2 flex gap-2 justify-end items-center">

      <label
        class="flex-1"
        :class="{
          'text-red-600': v$.turnover_figure_proposal.turnover_in_period_from.$invalid ||
                            v$.turnover_figure_proposal.turnover_in_period_till.$invalid,
        }">im Zeitraum von / bis
      </label>
      <input
        type="date"
        placeholder="T.M.JJJJ"
        id="proposal_turnover_in_period_from"
        v-model="turnover_figure_proposal.turnover_in_period_from"
        :disabled="readonly"
        class="px-2 py-1 border border-gray-500 text-right"
      >
      <input
        type="date"
        placeholder="T.M.JJJJ"
        id="proposal_turnover_in_period_till"
        v-model="turnover_figure_proposal.turnover_in_period_till"
        :disabled="readonly"
        class="px-2 py-1 border border-gray-500 text-right"
      >
    </div>

    <p class="mt-5 mb-5 font-medium">
      Nachfolgende Daten sind aus Ihrer Steuerveranlagung und/oder Erfolgsrechnung (ER),
      bezogen auf das entsprechende Jahr zu entnehmen.
    </p>

    <div class="mt-2 grid gap-1 grid-cols-2 items-center">
      <label
        :class="{
          'text-red-600': v$.turnover_figure_proposal.energy_bill_period.$invalid,
        }">Sie erhalten die Energiekostenabrechnung</label>
      <yes-no v-model="turnover_figure_proposal.energy_bill_period"
              value-yes="1"
              value-no="2"
              label-yes="Im Quartal"
              label-no="monatlich"
              :readonly="readonly" />
    </div>

    <div class="mt-5 grid gap-1 grid-cols-4">
      <label class="font-medium col-span-3">Geschäftsjahr</label>
      <label class="font-medium text-right">2021</label>
    </div>

    <div class="mt-2 grid gap-1 grid-cols-4 items-center hover:bg-gray-300">
      <div class="col-span-3">
        Umsatz gemäss Ziffer 100 [CHF]
        <sup class="px-1.5 py-0.5 bg-gray-400 text-white rounded">1</sup>
      </div>
      <vue-numeric
        :disabled="readonly"
        thousand-separator="'"
        :minus="true"
        :precision="2"
        v-model.lazy="turnover_figure_proposal.netto_income_sales"
        class="w-full px-2 py-1 border border-gray-500 text-right"
        :class="{
          'border-red-600':
            v$.turnover_figure_proposal.netto_income_sales.$invalid,
        }"
        type="text"
      ></vue-numeric>
    </div>

    <div
         class="mt-2 grid gap-1 grid-cols-4 items-center hover:bg-gray-300">
      <div class="col-span-3">
        Übrige Erträge gemäss Ziffer 105 [CHF]
        <sup class="px-1.5 py-0.5 bg-gray-400 text-white rounded">2</sup>
      </div>
      <vue-numeric
        :disabled="readonly"
        thousand-separator="'"
        :minus="true"
        :precision="2"
        v-model.lazy="turnover_figure_proposal.other_income"
        class="w-full px-2 py-1 border border-gray-500 text-right"
        :class="{
          'border-red-600':
            v$.turnover_figure_proposal.other_income.$invalid,
        }"
        type="text"
      ></vue-numeric>
    </div>

    <div
         class="mt-2 grid gap-1 grid-cols-4 items-center hover:bg-gray-300">
      <div class="col-span-3">
        Inländische Energiekosten [CHF]
        <sup class="px-1.5 py-0.5 bg-gray-400 text-white rounded">3</sup>
      </div>
      <vue-numeric
        :disabled="readonly"
        thousand-separator="'"
        :minus="true"
        :precision="2"
        v-model.lazy="turnover_figure_proposal.domestic_energy_costs"
        class="w-full px-2 py-1 border border-gray-500 text-right"
        :class="{
          'border-red-600':
            v$.turnover_figure_proposal.domestic_energy_costs.$invalid,
        }"
        type="text"
      ></vue-numeric>
    </div>

    <div
         class="mt-2 grid gap-1 grid-cols-4 items-center hover:bg-gray-300">
      <div class="col-span-3">
        Ergebnis vor Steuer gemäss Ziffer 190 u. 185 [CHF]
        <sup class="px-1.5 py-0.5 bg-gray-400 text-white rounded">4</sup>
      </div>
      <vue-numeric
        :disabled="readonly"
        thousand-separator="'"
        :minus="true"
        :precision="2"
        v-model.lazy="turnover_figure_proposal.earnings_before_tax"
        class="w-full px-2 py-1 border border-gray-500 text-right"
        :class="{
          'border-red-600':
            v$.turnover_figure_proposal.earnings_before_tax.$invalid,
        }"
        type="text"
      ></vue-numeric>
    </div>

    <p class="mt-4">
      <sup class="px-1.5 py-0.5 bg-gray-400 text-white rounded">1</sup>
      gemäss Ziffer 100 in der Steuererklärung 2021
    </p>
    <p>
      <sup class="px-1.5 py-0.5 bg-gray-400 text-white rounded">2</sup>
      gemäss Ziffer 105 in der Steuererklärung 2021
    </p>
    <p>
      <sup class="px-1.5 py-0.5 bg-gray-400 text-white rounded">3</sup>
      Massgebend sind sämtliche Energiekosten (d.h. Strom, Gas, Treibstoff, usw.)
    </p>
    <p>
      <sup class="px-1.5 py-0.5 bg-gray-400 text-white rounded">4</sup>
      Ziffer 190 zuzüglich Ziffer 185 der Steuererklärung 2021
    </p>

    <div class="mt-5">
      <h2 class="font-semibold">3b. Dokumente für die Steuerverwaltung (STV) hochladen</h2>
      <p class="mt-2">
        Laden Sie bitte folgende PDF-Dokumente hoch:<br>
        <ul class="list-inside list-disc">
          <li>Detaillierte und gegliederte Erfolgsrechnung 2021 mit Kontonummern;</li>
          <li>Details zu den deklarierten inländischen Energiekosten (Kontoblätter)</li>
        </ul>
      </p>

      <file-upload
        ref="upload_files"
        v-model="upload_files"
        :drop="!readonly"
        :drop-directory="false"
        :multiple="false"
        class="w-full"
        :disabled="readonly"
        @input-filter="input_filter"
        @input-file="input_file"
      >
        <div
          class="p-3 mt-4 flex flex-wrap gap-4 items-center place-items-center border border-dashed border-gray-600 rounded w-full"
          :class="{
            'bg-green-300': $refs.upload_files && $refs.upload_files.dropActive
          }"
          style="min-height: 120px;"
        >
          <div
            v-if="turnover_figure_proposal?.documents?.length <= 0"
            class="text-center w-full text-sm text-gray-500"
          >
            Klicken Sie in dieses Feld um Dateien hochzuladen<br>
            oder ziehen Sie vom Windows Explorer oder MacOS Finder PDF
            Dokumente über diesen Bereich<br>
            und lassen Sie diese dann fallen um Dateien hochzuladen.
          </div>
          <div v-for="file in turnover_figure_proposal.documents" :key="file.id" class="flex gap-4 z-10">
            <div
              class="relative p-2 border flex flex-col justify-between w-40 h-32 items-center hover:bg-green-100 hover:cursor-pointer"
            >
              <div
                v-if="!readonly"
                class="bg-gray-700 text-white absolute top-0 right-0 px-2 py-1 opacity-20 hover:opacity-100">

                <button @click="remove_download(file.id)" class="hover:text-red-500">
                  <font-awesome-icon icon="trash" />
                </button>
              </div>

              <a :href="file.filepath" target="_blank" class="z-20">
                <img src="../images/pdf.png" class="w-12 mt-2">
              </a>
              <span class="text-xs break-all">{{ file.name || file.filename }}</span>

              <div v-if="progress > 0" class="w-48">
                <div id="upload-progress-bar" class="h-3 rounded bg-green-400 w-0" :style="{ width: progress + '%' }"></div>
              </div>
            </div>
          </div>
        </div>
      </file-upload>

    </div>

</div>
</template>

<script>
import EventBus from '../utils/event-bus.js'
import Api from '../utils/api'

import FileUpload from 'vue-upload-component'
import { DirectUpload } from '@rails/activestorage'
import YesNo from './yes_no.vue'

import { mapActions } from 'vuex'

import { useVuelidate } from '@vuelidate/core'
import {
  required,
  requiredIf,
  decimal,
  between,
  maxValue
} from '@vuelidate/validators'

function validateIf(condition, rule) {
  return condition ? rule : {}
}
const not_zero = (value) => ( value > 0 || value < 0 ) && value > -999_999_999 && value < 999_999_999

export default {
  name: 'TurnoverFigureProposal',
  components: {
    FileUpload,
    YesNo
  },
  props: {
    proposal: {
      type: Object,
      required: true
    },
    turnover_figure_proposal: {
      type: Object,
      required: true
    },
    debug: {
      type: [Number, String, Boolean],
      default: false
    },
    readonly: {
      type: [Number, String, Boolean],
      default: true
    }
  },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      progress: 0,
      old: Object.assign({}, this.turnover_figure_proposal),
      tmp_display_mitigation_question: false,
      turnover_in_period: null,
      upload_files: []
    }
  },
  methods: {
    ...mapActions(['update_turnover_figure_proposal']),
    directUploadWillStoreFileWithXHR(request) {
      console.log('>>> uploading', request)
      request.upload.addEventListener('progress', (evt) =>
        this.directUploadDidProgress(evt)
      )
    },
    directUploadDidProgress(event) {
      console.log('>>> uploading progress', event)
      this.progress = Math.floor((event.loaded / event.total) * 100)
    },
    async remove_download(file_id) {
      console.log('remove file', file_id)
      await Api().get(`uploads/remove/${file_id}`)
                 .then((r) => {
                   this.turnover_figure_proposal.documents.splice(
                     this.turnover_figure_proposal.documents.find((e) => e.id == file_id),
                     1
                   )
                 })
                 .catch((e) => console.log('error removing file', e))
    },
    input_filter(new_file, old_file, prevent) {
      console.log('input_filter', new_file, old_file)
      if (!/\.(pdf|jpeg|jpe|jpg|gif|png|webp)$/i.test(new_file.name)) {
        return prevent()
      }
      console.log('input_filter', this?.turnover_figure_proposal?.documents?.length)
    },
    input_file(new_file, old_file) {
      console.log('input_file, new_file:', new_file, 'old_file:', old_file)

      if (!new_file) return

      if (!this.turnover_figure_proposal?.documents) this.turnover_figure_proposal.documents = []
      this.progress = 1
      const url = `api/v1/uploads`

      const upload = new DirectUpload(
        new_file?.file,
        `${url}?session_id=${this.session_id}&turnover_figure_proposal_id=${this.turnover_figure_proposal.id}`,
        this
      )

      upload.create((error, blob) => {
        if (error) {
          console.log('got an error')
        } else {
          this.progress = 0
          console.log('successfully', blob)
          if (blob.virus_found) {
            alert(
              "Bei der hochgeladenen Daten wurde eine Auffälligkeit festgestellt.\n" +
              "Es wurde eine Virensignatur gefunden.\n" +
              "Bitte überprüfen Sie die Daten selbst und laden Sie eine alternative Datei hoch.")
          } else {
            this.blob = blob
            this.turnover_figure_proposal.documents.push(blob)
          }
        }
      })
    },
  },
  watch: {
    turnover_figure_proposal: {
      handler(n, o) {
        if (this.readonly) return

        let changed_data = new Map(Object.entries(n))
        console.log('turnover_figure_proposal change_data', changed_data)

        // delete all values if they are equal
        changed_data.forEach((v, k, m) => {
          if (changed_data.get(k) === this.old[k] && k !== 'id') m.delete(k)
        })
        changed_data.set('calc_loss', this.calc_loss)

        // store old values to find out what has changed then next time
        this.old = Object.assign({}, n)

        if (changed_data && changed_data.size > 0) {
          const data = {
            id: this.turnover_figure_proposal.id,
            proposal_id: this.proposal.id,
            model: 'turnover_figure_proposal',
            data: Object.fromEntries(changed_data)
          }
          // EventBus.$emit("update-turnover-figure-proposal", data )
          this.update_turnover_figure_proposal(data).then((response) => {
            console.log('turnover_figure_proposal:key_stroke:', response.data)
            this.turnover_figure_proposal.id = response.data.id
          })
          this.$emit('update-id', this.turnover_figure_proposal.id)
        }
        // if (!this.v$.$invalid) {
        EventBus.$emit('turnover-figure-form-valid', !this.v$.$invalid)
        // }
      },
      deep: true
    }
  },
  mounted() {},
  computed: {
    display_mitigation_question() {
      return this.payment_period?.display_mitigation_question
    }
  },
  validations() {
    return {
      turnover_figure_proposal: {

        energy_bill_period: { required },
        // turnover_in_period: { required },
        turnover_minimum_reached: { required },

        netto_income_sales: { required, decimal, between: between(1, 999999999) },
        other_income: { required, decimal, between: between(1, 999999999) },
        domestic_energy_costs: {
          required,
          decimal,
          between: between(1, 999999999)
        },
        earnings_before_tax: {
          required,
          decimal,
          maxValue: not_zero
        },
        turnover_in_period_from: {
          required: requiredIf(
            this.turnover_figure_proposal.turnover_minimum_reached <= 0
          )
        },
        turnover_in_period_till: {
          required: requiredIf(
            this.turnover_figure_proposal.turnover_minimum_reached <= 0
          )
        },
        turnover_2021: {
          decimal,
          between: validateIf(
            this.turnover_figure_proposal.turnover_minimum_reached <= 0,
            between(1, 999999999)
          ),
          required: requiredIf(
            this.turnover_figure_proposal.turnover_minimum_reached <= 0
          )
        }
      }
    }
  }
}
</script>
