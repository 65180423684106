import Axios from "axios";

export default () => {
  var token = document.getElementsByName("csrf-token")[0].getAttribute(
    "content",
  );
  const AxiosInstance = Axios.create({
    baseURL: "/api/v1",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      "Credentials": "same-origin",
      "Accept": "application/json",
      "X-CSRF-Token": token,
    },
  });
  AxiosInstance.interceptors.response.use((response) => {
    return response;
  }, function (error) {
    // console.log('Axios interceptor ....')
    if (error.response.status === 401) {
      // console.log('unauthorized, logging out ...')
      window.document.location.href = "/";
    }
    return Promise.reject(error.response);
  });
  return AxiosInstance;
};
