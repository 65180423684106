<template>
  <div
    @click.self="close_window"
    class="fixed w-full h-full inset-0 flex z-30">
    <div
      class="m-auto overflow-auto max-h-screen shadow-xl xlogin-window p-5 border-2 border-gray-200 max-w-2xl bg-white"
    >
      <h1 class="font-semibold text-lg flex justify-between">
        <span>Umsatzzahlen erfassen</span>
        <span
          @click="close_window"
          class="cursor-pointer right-0 -mt-2 text-gray-800"
        >
          <font-awesome-icon icon="times" />
        </span>
      </h1>

      <turnover-figure-proposal
        v-if="proposal_id"
        class="mt-6"
        :debug="debug"
        :proposal="proposal"
        :turnover_figure_proposal="turnover_figure_proposal"
        :readonly="readonly"
      />

      <div class="mt-6">
        <div class="mt-3 flex justify-end items-center">
          <button
            v-if="is_valid() && !readonly"
            @click="submit_turnover_figure_proposal"
            class="ml-1 text-white px-3 py-2 shadow-sm"
            :class="{
              'bg-blue-500': valid_form_data,
              'bg-gray-500': !valid_form_data,
            }"
            :disabled="!valid_form_data"
          >
            Umsatzzahlen deklarieren. Zur Prüfung freigeben.
          </button>
        </div>

        <div
          v-if="message"
          class="mt-2 p-2 text-sm bg-red-700 text-white rounded"
        >
          {{ message }}
        </div>

        <p v-if="!readonly" class="mt-4 text-sm">
          Die Daten automatisch gespeichert.
          Sollten Sie die Bearbeitung nicht abschliessen können, so schliessen Sie das Fenster.
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../utils/event-bus.js'
import { mapGetters, mapActions } from 'vuex'
import TurnoverFigureProposal from './turnover-figure-proposal.vue'

export default {
  props: {
    proposal_id: {
      type: [String, Number],
      required: true
    },
    turnover_figure_proposal_id: {
      type: [String, Number]
    },
    debug: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      readonly: false,
      form_data_valid: false,
      payment_period_id: null,
      proposal: {},
      turnover_figure_proposal: {},
      message: ''
    }
  },
  mounted() {
    this.proposal = this.proposals.find( (e) => e.id == this.proposal_id)
    console.log('mounted proposal', this.proposal)

    if (this.turnover_figure_proposal_id) {
      this.turnover_figure_proposal =
        this.proposal
            .turnover_figure_proposals
            .find((e) => e.id == this.turnover_figure_proposal_id)

      console.log('mounted turnover-figure-proposal', this.turnover_figure_proposal)
    }

    if (!this.turnover_figure_proposal_id) {
      // find a turnover that is still open?
      this.turnover_figure_proposal = this.proposal.turnover_figure_proposals.find(
        (e) => e.status == -1 || e.status == 0 || e.status == 20 || e.status == 100
      )
      if (!this.turnover_figure_proposal) {
        this.turnover_figure_proposal = {
          id: null,
          proposal_id: this.proposal.id,
          netto_income_sales: 0,
          other_income: 0,
          domestic_energy_costs: 0,
          earnings_before_tax: 0,
          turnover_2021: 0,
          energy_bill_period: 0,
          turnover_in_period: 0,
          turnover_in_period_from: null,
          turnover_in_period_till: null,
          turnover_minimum_reached: 0,
          status: -1,
          documents: []
        }
      }
    } else {
      this.turnover_figure_proposal = this.proposal?.turnover_figure_proposals?.find(
        (e) => e.id == this.turnover_figure_proposal_id
      )
    }

    this.readonly = this?.turnover_figure_proposal?.status >= 0

    EventBus.$on('turnover-figure-form-valid', (data) => {
      console.log('turnover-figure-form-valid', data)
      this.form_data_valid = data
    })
  },
  computed: {
    ...mapGetters(['proposals', 'payment_periods']),
    valid_form_data() {
      return this.form_data_valid
    }
  },
  methods: {
    ...mapActions(['release_turnover_figure_proposal', 'get_proposals']),
    is_valid() {
      return true
    },
    close_window() {
      EventBus.$emit('close-turnover-figure-window')
    },
    submit_turnover_figure_proposal() {
      // console.log(
      //  "submit_turnover_figure_proposal",
      //  this.turnover_figure_proposal
      // )
      this.message = ''
      this.release_turnover_figure_proposal({
        id: this.turnover_figure_proposal.id,
        proposal_id: this.proposal.id
      })
        .then((response) => {
          console.log(response.status)
          this.get_proposals()
          this.close_window()
        })
        .catch((error) => {
          // console.log("error", error)
          this.message =
            error ||
            error.data ||
            error.data.message ||
            'Beim Speichern Ihrer Daten trat ein Problem auf. Bitte versuchen Sie es später nochmal.'
        })
    }
  },
  components: {
    TurnoverFigureProposal
  }
}
</script>
